password-modal {
    dialog-content {
        input-group {
            @include is-mobile {
                input {
                    font-size: 20px;
                }
            }
        }

        a.forgot {
            display: inline-block;
            width: 65px;
        }

        button {
            @include gen-button;
            background-color: color(carrot-orange);
            border-color: color(trinidad);
            font-size: 14px;
            font-weight: 600;
            height: 40px;
            min-width: 205px;
            padding: 7px 14px;

            @include is-mobile {
                min-width: 140px;
            }

            &:active {
                background-color: color(carrot-orange);
            }
        }
    }
}
