autocomplete-group {
    display: block;
    position: relative;

    &.has-error {
        .autocomplete-group-list {
            border-bottom: 1px solid color(color-red);
            border-left: 1px solid color(color-red);
            border-right: 1px solid color(color-red);
        }
    }

    &:focus {
        .autocomplete-group-list {
            box-shadow: 0 5px 6px 0 color(dusty-gray);
        }
    }

    &.is-height-auto .autocomplete-group-list {
        top: 41px;
    }

    .autocomplete-group-list {
        background-color: color(alabaster);
        border-bottom: 1px solid color(dusty-gray);
        border-left: 1px solid color(dusty-gray);
        border-right: 1px solid color(dusty-gray);
        display: flex;
        flex-direction: column;
        left: 0;
        max-height: 115px;
        overflow-x: scroll;
        position: absolute;
        right: 0;
        top: 66px;
        z-index: 4;

        a {
            color: color(color-black);
            padding: 3px 5px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    p.is-error {
        position: absolute;
        right: 3px;

        @include is-tablet-portrait {
            font-size: 8px;
        }
    }
}