section.product-teeup,
section.product-report {
    img.product-logo {
        display: block;
        height: 65px;
        margin: 15px auto;
    }

    h5 {
        margin: 10px 0;
    }

    .cosmic-updates {
        border-radius: 20px;
        margin: 50px 0 25px;
        position: relative;

        img {
            left: -25px;
            position: absolute;
            top: -40px;
            width: 85px;

            @include is-mobile {
                left: calc(50% - 42.5px);
                top: -25px;
            }
        }

        row {
            border-radius: 20px;
            padding: 20px 50px 9px;

            @include is-mobile {
                padding: 45px 10px 30px;
            }
        }
    }

    .report-links {
        margin: auto auto 45px auto;
        max-width: 90%;

        @include is-not-mobile {
            width: 750px;
        }

        a {
            display: block;
            font-size: 17px;
            margin-bottom: 12px;
        }
    }

    .report-item {
        border-top: 1px solid color(silver-sand);
        margin: auto;
        max-width: 90%;
        position: relative;

        @include is-not-mobile {
            width: 750px;
        }

        .report-item-tab {
            display: block;

            &:hover {
                text-decoration: none;
            }
        }

        &.is-closed {
            .report-item-tab {
                &::after {
                    border-bottom: none;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 7px solid color(alto);
                    content: "";
                    position: absolute;
                    right: 12px;
                    top: calc(50% - 7px);
                }
            }
        }

        &.is-open {
            border: 0;

            .report-item-tab {
                border-top: 1px solid color(silver-sand);
                box-shadow: 0 4px 6px -4px color(gallery);
                transform: scale(1.2), translate(-1px, -2px);
                z-index: 2;

                &::after {
                    border-bottom: 7px solid color(alto);
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    content: "";
                    position: absolute;
                    right: 12px;
                    top: calc(50% - 7px);
                }
            }

            &::after {
                border-bottom: 7px solid color(silver-sand);
                border-top: none;
            }

            .report-item-content {
                background-color: transparent;
                display: block;
                margin-bottom: 10px;
                margin-top: 10px;
                max-height: 10000em;
                min-height: 200px;
                opacity: 1;
                padding-bottom: 20px;
            }
        }

        > a {
            color: color(color-black);
            display: block;
            padding: 10px 0;
            position: relative;

            &:hover {
                background-color: color(polar);
                text-decoration: none;
            }

            h5 {
                margin-top: 0;
            }
        }

        .report-item-content {
            display: none;
            max-height: 0;
            opacity: 0;
            overflow: auto;
            padding: 0 15px;
            transition: max-height 0.35s, opacity 0.4s, translate 0.2s, scale 0.2s ease-out;

            h6 {
                margin-bottom: 10px;
            }

            b {
                font-weight: 500;
            }
        }
    }

    .back-to-top {
        bottom: 15px;
        left: 100%;
        position: sticky;
        width: 75px;

        @include is-desktop {
            width: 0;
        }

        a {
            background-image: url("https://gfx.tarot.com/images/products/big-picture-forecast-redux/top-btn.svg");
            background-repeat: no-repeat;
            display: block;
            height: 75px;
            width: 75px;

            @include is-desktop {
                display: block;
                margin-left: 10px;
            }
        }
    }

    .birthdate-error {
        margin: -20px 0 5px 0;
    }
}
