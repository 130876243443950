//sass-lint:disable no-important
.sui-search-box__submit {
    background: color(red-violet) !important;
    border-bottom-color: color(lipstick) !important;
    font-family: $font-montserrat !important;
    margin-left: 10px !important;
    padding: 7px 14px !important;

    &:hover {
        background: color(red-violet) !important;
    }
}

.sui-facet,
.sui-layout-sidebar,
.sui-layout-sidebar-toggle,
.sui-paging-info,
.sui-search-box,
.sui-select,
.sui-sorting {
    font-family: $font-montserrat !important;
}

.sui-layout-sidebar--toggled {
    display: block !important;
    padding-right: 15px !important;
}

.sui-layout-header {
    padding: 0 0 20px !important;
    width: 100%;

    .sui-layout-header__inner {
        font-family: $font-montserrat !important;
        margin-left: auto;
        margin-right: auto;
        max-width: 1300px;
        padding: 0 24px;

        @include is-mobile {
            padding: 0 15px;
        }
    }
}

.sui-facet__title,
.sui-multi-checkbox-facet__option-count,
.sui-sorting__label {
    color: color(color-black) !important;
}

.sui-multi-checkbox-facet__option-count {
    padding-right: 3px;
}

.sui-result {
    font-family: $font-montserrat !important;

    em {
        background: none !important;
        color: color(red-violet) !important;

        &::after {
            background: transparent !important;
        }
    }

    a {
        color: color(color-black);

        &.no_underline_hover:hover {
            text-decoration: none;
        }
    }

    .sui-result__title {
        color: color(color-black) !important;
        font-size: 42px !important;

        &:hover {
            text-decoration: underline;
        }

        @include is-mobile {
            font-size: 32px !important;
        }
    }
}

.sui-layout-sidebar-toggle {
    border: 1px solid color(color-black) !important;
    color: color(color-black) !important;
}

.sui-layout-main {
    padding: 20px 0 20px 32px !important;
    @include is-mobile {
        padding-left: 0 !important;
    }
}

.sui-layout-main-body {
    margin-top: 20px !important;

    p {
        font-size: 17px !important;
    }

    .breadcrumb {
        font-size: 12px;

        span {
            display: inline-block;
            font-size: 12px;
        }
    }
}

.sui-result__body {
    @include is-mobile {
        display: block !important;
    }
}

.sui-result__image {
    max-width: 140px;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @include is-mobile {
            max-height: 250px;
            max-width: none;
            width: auto;
        }
    }

    @include is-mobile {
        max-width: none;
    }
}

.sui-paging {
    font-family: $font-montserrat !important;
    margin-top: 15px;
}
