vip-nav {
    display: block;
    margin-bottom: 20px;

    a {
        border-top: 1px solid color(alto);
        color: color(eminence);
        display: block;
        padding: 15px 0;

        &:last-child {
            border-bottom: 1px solid color(alto);
        }

        img {
            height: 40px;
        }
    }
}