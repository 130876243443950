select-group {
    display: block;
    height: 69px;
    position: relative;

    &::after {
        @include disclosure-triangle;
        float: none;
        position: absolute;
        right: 10px;
        top: 24px;
        z-index: 3;
    }

    &.has-label {
        height: 88px;
        white-space: nowrap;

        &::after {
            top: 42px;
        }

        span {
            top: 37px;
        }
    }

    &.has-error {
        select {
            border-color: color(color-red) !important; //sass-lint:disable-line no-important
        }
    }

    span {
        color: color(boulder);
        font-size: 11px;
        left: 10px;
        position: absolute;
        top: 18px;
        z-index: 1;
    }

    select {
        appearance: none;
        background: transparent;
        border: 1px solid color(alto);
        border-radius: 5px;
        box-shadow: inset 0 1px 2px color(color-black, 0, 0.1);
        color: color(color-black);
        display: block;
        font-family: $font-montserrat;
        font-size: 24px;
        font-weight: 400;
        height: 45px;
        margin: 5px 0;
        padding: 7px;
        position: absolute;
        transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
        width: 100%;
        z-index: 2;

        &:focus {
            background-color: color(alabaster);
            border-color: color(dusty-gray);
            box-shadow: 0 0 5px color(dusty-gray);
            outline: none;
        }

        &[disabled] {
            background-color: color(gallery);
            cursor: not-allowed;
        }

        option:disabled {
            display: none;
        }
    }
}
