textarea-group {
    display: block;
    height: 200px;

    &.has-error {
        textarea {
            border-color: color(color-red);
        }
    }

    textarea {
        appearance: none;
        background: transparent;
        border: 1px solid color(alto);
        border-radius: 5px;
        box-shadow: inset 0 1px 2px color(color-black, 0, 0.1);
        color: color(color-black);
        display: block;
        font-family: $font-montserrat;
        font-size: 24px;
        font-weight: 400;
        height: 150px;
        margin: 5px 0;
        padding: 7px;
        transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
        width: 100%;

        &:focus {
            background-color: color(alabaster);
            border-color: color(dusty-gray);
            box-shadow: 0 0 5px color(dusty-gray);
            outline: none;
        }

        &[disabled] {
            background-color: color(gallery);
            cursor: not-allowed;
        }

        &::placeholder {
            color: color(boulder);
            font-size: 11px;
        }
    }
}