zodiac-dropdown,
.tarot-dropdown {
    cursor: pointer;
    display: block;
    position: relative;

    span {
        background-color: color(color-white);
        border-radius: 10px;
        display: block;
        font-size: 15px;
        min-width: 180px;
        padding: 18px 40px 18px 15px;
        position: relative;

        &::after {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            content: "";
            position: absolute;
            right: 15px;
            top: 24px;
        }

        &.active {
            box-shadow: 0 0 10px 0 color(color-black, 0, 0.3);
        }
    }

    > div,
    & + div {
        z-index: 1;
    }
}

zodiac-dropdown {
    margin: 20px 10px 20px 0;

    span {
        border: 1px solid color(red-violet);
        color: color(red-violet);
        margin: 0 0 5px 3px;
        padding: 18px 40px 18px 15px;

        &::after {
            border-bottom: 7px solid color(red-violet);
        }
    }

    > div,
    & + div {
        bottom: 70px;
        left: -10px;
        position: absolute;

        @include is-not-mobile {
            zodiac-sign-picker {
                background-color: color(color-white);
                margin: 0 0 0 10px;
                padding: 15px 40px;
                width: 100%;
            }
        }
    }

    @include is-mobile {
        margin: 0 10px 20px 10px;

        span {
            margin-left: 6px;
            padding: 17px 39px 17px 9px;

            @include is-tiny {
                font-size: 14px;
                padding: 18px 35px 18px 5px;
            }

            &::after {
                border-bottom: none;
                border-top: 7px solid color(red-violet);
            }
        }

        > div {
            position: static;

            a {
                display: block;
                font-family: $font-montserrat;
                margin: 5px 10px 0 10px;
                padding: 18px 15px;
                width: initial;
            }
        }
    }
}


.tarot-dropdown {
    span {
        border: 1px solid color(cerulean);
        color: color(cerulean);

        &::after {
            border-top: 7px solid color(cerulean);
        }
    }

    > div,
    & + div {
        display: none;
        padding-top: 4px;
        position: absolute;
        top: 58px;
        width: max-content;

        > a {
            background-color: color(color-white);
            border: 1px solid color(cerulean);
            border-radius: 10px;
            color: color(cerulean);
            display: block;
            font-size: 15px;
            margin: 5px 0 0;
            padding: 18px 15px;
        }
    }

    @include is-mobile {
        margin: 0;

        span {
            display: block;
            min-width: 0;
            padding: 18px 32px 18px 15px;

            @include is-tiny {
                font-size: 14px;
                padding: 18px 35px 18px 5px;
            }
        }

        & + div {
            flex-direction: column;
            padding-top: 4px;
            position: static;
            top: 58px;
            width: 100%;
        }
    }

    &.active {
        > div {
            display: block;
        }

        & + div {
            display: flex;
        }
    }
}

deck-selector {
    cursor: pointer;

    &::after {
        border-bottom: none;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid color(cerulean);
        content: "";
        position: absolute;
        right: 15px;
        top: 24px;
    }

    select {
        appearance: none;
        background-color: color(zumthor);
        border: 0;
        border-radius: 10px;
        color: color(cerulean);
        font-family: $font-montserrat;
        font-size: 15px;
        margin-bottom: 5px;
        min-height: 57px;
        padding: 18px 40px 18px 15px;
        width: 100%;

        &::after {
            border-bottom: none;
            border-top: 7px solid color(cerulean);
        }

        &:focus {
            outline: 0;
        }
    }
}