compatibility-chooser {
    display: block;

    @include is-tablet {
        width: 100%;
    }

    @include is-desktop {
        margin: 0 auto;
        width: 625px;
    }

    .compatibility-chooser {
        cursor: pointer;
        display: block;
        margin: 0 0 10px 0;
        position: relative;

        @include is-desktop {
            margin: 0 0 0 5px;
        }

        h2 {
            background-color: color(mercury);
            border: 1px solid color(color-white);
            color: color(silver-sand);
            font-size: 25px;
            font-weight: 300;
            height: 38px;
            margin: 0;
            padding: 1px 10px;
            position: relative;

            @include is-mobile {
                font-size: 25px;
                font-weight: 400;
            }

            &::after {
                @include disclosure-triangle;
                border-bottom: none;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 7px solid color(silver-sand);
                float: none;
                position: absolute;
                right: 17px;
                top: 40%;
            }
        }

        &.chosen h2 {
            background-color: color(eminence);
            color: color(color-white);

            &::after {
                border-top-color: color(color-white);
            }

            &:hover {
                border-color: color(color-white);
                color: color(color-white);
            }
        }

        small {
            color: inherit;
            display: none;
            font-size: 0.9rem;
            margin-left: 10px;

            &:first-of-type {
                display: inline-block;
            }
        }
    }

    .compatibility-chooser-dropdown {
        background-color: color(color-white);
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 1;

        &.shown {
            display: block;
        }
    }

    compatibility-chooser-dropdown-item {
        display: block;
        font-size: 25px;
        font-weight: 300;
        line-height: 35px;
        margin: 5px 0 0 0;
        padding: 1px 10px;

        @include is-mobile {
            font-weight: 400;
        }

        small {
            color: inherit;
            display: inline-block;
            font-size: 0.9rem;
            margin-left: 10px;
        }
    }

    date-select {
        @include is-desktop {
            display: inline-block;
            width: 50%;
        }

        fieldset {
            &.chosen {
                select {
                    background-color: color(eminence);
                    color: color(color-white);

                    &:hover {
                        background-color: color(eminence);
                    }
                }

                label[for="month"]::after,
                label[for="day"]::after,
                label[for="year"]::after {
                    border-top-color: color(color-white);
                }
            }
        }

        legend {
            color: color(boulder);
            font-size: 10px;
            font-weight: 400;
            padding: 8px 0;
        }

        select {
            appearance: none;
            background-color: color(mercury);
            background-image: none;
            border: 1px solid color(color-white);
            color: color(silver-sand);
            font-size: 16px;
            font-weight: 300;
            height: 39px;
            margin: 0;
            padding: 0 8px;
            text-transform: capitalize;

            &:hover {
                background-color: color(mercury);
                border-color: color(color-white);
            }

            &:focus {
                outline: none;
            }

            option {
                appearance: none;
                background-color: color(color-white);
                color: color(color-black);
                font-size: 12px;
                font-weight: 300;
            }
        }

        label {
            &[for="month"],
            &[for="day"],
            &[for="year"] {
                position: relative;
                z-index: 1;

                &::after {
                    @include disclosure-triangle;
                    border-bottom: none;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 7px solid color(silver-sand);
                    content: "";
                    float: none;
                    position: absolute;
                    right: 8px;
                    top: 40%;
                }
            }
        }
    }

    .button-wrapper {
        margin-top: 10px;
        text-align: center;
    }
}

compatibility-chooser-zodiac {
    @each $sign, $color in $zodiac-signs {
        h2.#{$sign},
        compatibility-chooser-dropdown-item.#{$sign} {
            background-color: $color;
            background-image: url("https://gfx.tarot.com/images/daily-pages/glyphs/light-#{$sign}.svg");
            background-position: right -3px;
            background-repeat: no-repeat;
            background-size: 80px;
            color: color(color-white);

            &:hover {
                background-color: darken($color, 10);
            }

            &::after {
                border: none;
            }
        }
    }
}
