@include is-mobile {
    header mobile-nav {
        background: color(color-white);
        bottom: 0;
        height: 100%;
        left: -100vw;
        position: fixed;
        top: 51px;
        transition: 250ms;
        visibility: hidden;
        width: 100%;
        z-index: 5;

        &.show-nav {
            left: 0;
            visibility: visible;
        }

        ul {
            list-style-type: none;
            margin-left: 0;

            li {
                font-size: 14px;
                line-height: 20px;
                padding: 10px 0;
                white-space: nowrap;
            }
        }
    }
}

@include is-tablet-portrait {
    header mobile-nav {
        background-color: transparent;
        bottom: 0;
        height: 100%;
        position: fixed;
        right: -100vw;
        top: 51px;
        transition: right 250ms ease-in-out, background-color 500ms ease-in-out;
        visibility: hidden;
        width: 100%;
        z-index: 5;

        &.show-nav {
            background-color: color(eminence, 0, 0.7);
            right: 0;
            visibility: visible;
        }

        ul {
            list-style-type: none;
            margin-left: 0;

            li {
                font-size: 14px;
                line-height: 20px;
                padding: 10px 0;
                white-space: nowrap;
            }
        }
    }
}

.toggle-menu {
    .icon {
        height: 26px;
        margin-top: 3px;
        position: relative;
        transform: rotate(0deg);
        width: 30px;

        span {
            background: color(eminence);
            border-radius: 2px;
            display: block;
            height: 4px;
            left: 0;
            opacity: 1;
            position: absolute;
            transform: rotate(0deg);
            width: 100%;

            &:nth-child(1) {
                top: 1px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 10px;
            }

            &:nth-child(4) {
                top: 19px;
            }
        }
    }

    &.open {
        .icon {
            span {
                background: color(chatelle);

                &:nth-child(1) {
                    left: 50%;
                    top: 18px;
                    width: 0%;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    left: 50%;
                    top: 18px;
                    width: 0%;
                }
            }
        }
    }
}

nav-bar-mobile {
    border-top: 1px solid color(alto);
    bottom: 0;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999999;

    @include is-mobile {
        left: 0;
    }

    @include is-tablet-portrait {
        width: 600px;
    }

    nav-left {
        background-color: color(color-black);
        height: 100%;
        width: 33%;

        a {
            color: color(color-white);
            display: flex;
            flex-direction: column;
            height: 10%;
            justify-content: center;
            padding: 0 11px;

            &.nav-left-my-account {
                height: auto;
                padding: 0;
            }

            &.active {
                &.nav-left-my-account {
                    background-color: color(vivid-violet);
                }

                &.nav-left-tarot {
                    background-color: color(red-violet);
                }

                &.nav-left-readings {
                    background-color: color(jungle-green);
                }

                &.nav-left-horoscopes {
                    background-color: color(shakespeare);
                }

                &.nav-left-love {
                    background-color: color(red-violet);
                }

                &.nav-left-astrology {
                    background-color: color(cinnabar);
                }

                &.nav-left-more-insight {
                    background-color: color(alizarin-crimson);
                }

                &.nav-left-shop {
                    background-color: color(kaitoke-green);
                }
            }
        }
    }

    nav-right {
        background-color: color(color-white);
        height: 100%;
        overflow-x: scroll;
        padding-bottom: 80px;
        width: 67%;

        > div {
            border-bottom: 1px solid color(gallery);
            padding: 5px 7px;

            &#nav-my-account { //sass-lint:disable-line no-ids
                border: none;
                padding: 0;
            }

            .nav-mobile > a,
            .nav-mobile > promo-message > a,
            nav-articles > a {
                color: color(trout);
                display: block;
                font-family: $font-baskerville;
                font-size: 26px;
                font-style: italic;
                line-height: 1.4em;
                margin: 15px 0 5px 0;
                white-space: normal;
            }

            ul {
                line-height: 2;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    white-space: pre-wrap;

                    a.nav-link,
                    a.nav-link:visited {
                        font-weight: 500;
                    }

                    img {
                        margin-top: 0;
                    }
                }
            }

            figure {
                max-height: none;
            }
        }
    }
}

nav-submenu {
    background: color(color-white, 0, 0.98);
    box-shadow: -6px 15px 15px 0 color(color-black, 0, 0.05);
    display: none;
    justify-content: center;
    left: 0;
    overflow: hidden;
    padding: 9px 0;
    position: absolute;
    top: 68px;
    width: 100%;
    z-index: 21;

    ul {
        list-style: none;

        li {
            line-height: 20px;
            padding-top: 8px;
        }
    }
}

.submenu-category {
    margin: 0 25px 0 15px;
    white-space: nowrap;
    width: 235px;

    @include is-mobile {
        margin: 0;
        min-width: 0;
    }

    @include is-tablet-portrait {
        margin: 0;
    }

    &.nav-full > a,
    &.nav-full > promo-message > a {
        color: color(trout);
        font-family: $font-baskerville;
        font-size: 26px;
        font-style: italic;
        font-weight: 500;
        line-height: 36px;
        margin: 2.8px 0 7px 0;
    }

    a.nav-link,
    a.nav-link:visited {
        color: color(eminence);
        font-size: 15px;

        &:hover {
            color: color(color-black);
        }

        @include is-tablet-landscape {
            font-size: 14px;
        }
    }

    li:last-child  {
        padding-top: 7px;

        a.nav-link {
            color: color(eminence);
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;

            &:hover {
                color: color(color-black);
            }
        }
    }
}