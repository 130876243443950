.hexagram-browser {
    margin: 15px 0 0 0;

    a {
        color: color(boulder);

        img {
            display: block;
            height: 60px;
            margin: 0 auto;
        }

        p {
            margin: 0 0 10px 0;
            text-align: center;
        }
    }
}

.sale-msg {
    background: color(bon-jour);
    color: color(eminence);
    margin: 0 0 10px;
    padding: 10px;
    text-align: center;
}

.kc-sale-msg {
    background: color(eminence, 0, 0.15);
    color: color(eminence);
    padding: 10px;
    text-align: center;
}

reading-header-price {
    .reading-price {
        color: color(alto);

        .sale {
            color: color(eminence);
            display: block;
            line-height: 15px;
        }
    }
}

.service-banner {
    background-image: url("https://gfx.tarot.com/images/banner/tarot-pink-lavender-banner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 125px;

    @include is-not-mobile {
        height: 200px;
    }
}

.browser-item {
    div {
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 95%;
    }
}

.main-hexagram-image {
    max-width: 320px;
    width: 100%;
}

.error-page-image {
    @include is-mobile {
        width: 140px;
    }
}

.bt-cards {
    opacity: 0.6;
}

.card-carousel {
    align-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    overflow-x: hidden;
    position: relative;

    @include is-mobile {
        overflow-x: scroll;
    }

    a {
        flex-shrink: 0;
        margin-right: 10px;
        width: calc(16.6666666667% - 10px);

        @include is-mobile {
            width: 103px;
        }

        > img {
            display: block;
            width: 100%;
        }
    }
}

// sass-lint:disable no-important
button[class^="button_button"] {
    background-color: color(eminence) !important;

    &:hover {
        color: color(lavender-purple) !important;
    }
}

button[class^="button_button"].button_invert--1bse9,
input[type="button"][class^="button_button"].button_invert--1bse9 {
    background-color: color(color-white) !important;
    border-color: color(eminence) !important;
    color: color(eminence) !important;

    &:hover {
        background-color: color(color-white) !important;
        color: color(lavender-purple) !important;
    }
}

div[class^="app_gdpr"] a {
    color: color(color-blue) !important;
}

div[class^="purposes_active"] {
    color: color(eminence) !important;
}

span[class^="switch_switch"] {
    &.switch_isSelected--17KWm span[class^="switch_visualizationContainer"] {
        background-color: color(eminence) !important;
    }
}

span[class^="closebutton_closeButton"] {
    fill: color(eminence) !important;

    &.closebutton_hasBorder--1W5To {
        box-shadow: 0 0 0 2px color(lavender-purple) !important;
    }
}

div[class^="footer_footer"] {
    border-top-color: color(eminence) !important;
}
// sass-lint:enable no-important

password-reset,
password-reset-set {
    img {
        @include is-mobile {
            width: 40%;
        }
    }
}

aside.details {
    margin: 20px 2%;
    position: relative;
    text-align: left;

    button {
        background: none;
        border: none;
        color: color(alto);
        cursor: pointer;
        font-size: 28px;
        position: absolute;
        right: 0;
        top: -14px;

        @include is-mobile {
            top: -28px;
        }

        &:active {
            outline: none;
        }
    }

    dt {
        display: inline;
        font-size: 17px;
        font-weight: 600;
        line-height: 28px;
    }

    dd {
        display: inline;
        font-size: 17px;
        line-height: 28px;
        margin-inline-start: 0;
    }
}

i.pagination-arrow {
    border-bottom: 3px solid color(color-black);
    border-right: 3px solid color(color-black);
    display: inline-block;
    padding: 2px;

    &.right {
        transform: rotate(-45deg);
    }

    &.left {
        transform: rotate(135deg);
    }
}

.pagination-circle {
    background-color: color(color-white);
    border: 1px solid color(color-black);
    border-radius: 50%;
    color: color(color-black);
    display: flex;
    height: 32px;
    justify-content: center;
    line-height: 32px;
    margin: -5px auto;
    text-decoration: none;
    width: 32px;

    &:hover {
        background-color: color(color-black);
        color: color(color-white);
        text-decoration: none;
    }

    &.active {
        background-color: color(color-black);
        border: 1px solid color(color-white);
        color: color(color-white);
        cursor: default;
    }
}

.no-hover {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

tarot-deck-selector,
tarot-card-selector {
    display: block;
    margin-bottom: 0;
    padding: 10px;

    select {
        background-color: color(color-white) !important; //sass-lint:disable-line no-important
        color: color(eminence) !important; //sass-lint:disable-line no-important
        font-size: 14px !important; //sass-lint:disable-line no-important
    }

    @include is-mobile {
        padding: 5px 10px;

        select-group {
            height: 45px;

            &::after {
                top: 19px;
            }

            select {
                height: auto;
            }
        }
    }
}

tarot-card-autocomplete,
tarot-deck-autocomplete {
    input-group {
        @include is-mobile {
            &::after {
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 7px solid color(eminence);
                content: "";
                position: absolute;
                right: 15px;
                top: 20px;
            }
        }

        input {
            background-color: color(color-white);
            color: color(eminence);
            font-size: 14px;

            &::placeholder {
                color: color(eminence);
                font-size: 14px;
            }
        }
    }
}

.is-height-101 {
    height: 101px;
}

.is-height-180 {
    height: 180px;
}

.tarot-deck-suit::before {
    content: "";
    display: block;
    height: 173px;
    margin: -173px 0 0;

    @include is-mobile {
        height: 203px;
        margin: -203px 0 0;
    }

    @include is-tablet-portrait {
        height: 195px;
        margin: -195px 0 0;
    }
}

.browse-tarot-decks-by-theme::before {
    content: "";
    display: block;
    height: 173px;
    margin: -173px 0 0;

    @include is-mobile {
        height: 215px;
        margin: -215px 0 0;
    }

    @include is-tablet-portrait {
        height: 156px;
        margin: -156px 0 0;
    }
}

.tarot-card-meaning {
    scroll-margin-top: 175px; //sass-lint:disable-line no-misspelled-properties
}

aside.whizzco {
    min-height: 925px;
    padding: 0 10px;

    @include is-not-mobile {
        min-height: 720px;
        padding: 0;
    }

    h2 {
        margin: 10px 0 -20px 0;
        padding-bottom: 5px;

        @include is-mobile {
            font-size: 16.8px;
        }
    }
}

label {
    color: color(color-black);

    &.checkbox {
        display: block;
        margin-top: 10px;

        &.birth-time {
            margin-top: 25px;

            small {
                margin: -25px 0 0 35px;
            }
        }
    }

    small {
        color: color(boulder);
        display: inline-block;
        font-size: 12px;
        vertical-align: top;

        b {
            color: color(color-black);
            font-weight: 500;
        }

        p {
            font-size: 12px;
        }
    }
}

policy-consent {
    display: none;

    p {
        color: color(color-white);
        font-size: 13px;
        line-height: 18.2px;
        margin: 0 0 10px 0;
    }

    a {
        color: color(color-white);
        text-decoration: underline;

        &:hover,
        &:active,
        &:visited,
        &:focus {
            color: color(color-white);
        }

        &.button {
            background: color(deep-cerulean);
            border-bottom-color: color(deep-cerulean, -10);
            margin-top: 3px;
            text-transform: none;
            width: 110px;

            &:hover {
                background-clip: padding-box;
                border-top-color: transparent;
            }
        }

        &.close {
            color: color(color-white);
            cursor: default;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            opacity: 1;
            position: absolute;
            right: 10px;
            text-decoration: none;
            text-shadow: none;
            top: 5px;
        }
    }

    &.shown {
        background: color(color-black, 0, 0.7);
        border-radius: 10px;
        display: block;
        margin: auto 10px;
        max-width: 395px;
        padding: 15px;
        position: fixed;
        top: 54%;
        z-index: 200;

        @include is-mobile {
            top: 48%;
        }
    }
}

dialog {
    align-content: center;
    align-items: center;
    background: transparent;
    border: none;
    bottom: 0;
    display: none;
    height: 100vh;
    justify-content: center;
    left: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 6000000 !important; //sass-lint:disable-line no-important

    &[open] {
        display: flex;
    }

    > :first-child {
        background-color: color(color-white);
        border-radius: 10px;
        display: block;
        width: 575px;

        @include is-mobile {
            width: 100%;
        }
    }

    dialog-header {
        display: block;
        padding: 9px 10px 0;
        position: relative;

        button {
            background: transparent;
            border: none;
            color: color(color-black, 0, 0.2);
            cursor: pointer;
            font-size: 20px;
            font-weight: 600;
            height: 20px;
            line-height: 20px;
            outline: none;
            padding: 0;
            position: absolute;
            right: 15px;
            text-align: right;
            text-shadow: 0 1px 0 color(color-white);
            top: 10px;
            width: 13px;
        }
    }

    dialog-content {
        display: block;
        padding: 15px 15px 20px;

        @include is-not-mobile {
            padding: 15px 35px 25px;
        }
    }

    dialog-footer {
        border-top: 1px solid color(gallery);
        display: block;
        padding: 9px 15px;
    }
}

dialog:not([open]) {
    display: none;
}

dialog + .backdrop,
dialog::backdrop {
    background-color: color(color-black, 0, 0.7);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 5999999 !important; //sass-lint:disable-line no-important

    @include is-mobile {
        background-color: color(eminence, 0, 0.7);
    }
}

.your-account-links {
    margin: 0 -10px;
    overflow-x: scroll;
    scrollbar-width: none; //sass-lint:disable-line no-misspelled-properties (Firefox-specific)

    &::-webkit-scrollbar { //sass-lint:disable-line no-vendor-prefixes
        display: none;
    }

    column {
        min-width: 150px;

        a:hover {
            cursor: pointer;
            text-decoration: none;
        }

        span {
            border-bottom: 1px solid color(color-black);
            display: inline-block;
            font-weight: 500;
            white-space: nowrap;
        }
    }
}

.homepage-guide img {
    height: 40px;
    width: 40px;
}

planet-tracker {
    display: block;
    margin-top: 40px;
    min-height: 460px;

    @include is-mobile {
        margin: 0 10px;
        min-height: 440px;
    }

    ul {
        list-style: none;
        margin: -5px 0 20px 0;

        li {

            a {
                display: inline-block;
                line-height: 28px;
                margin-right: 10px;
            }

            small {
                color: color(tundora);
                display: inline-block;
                font-size: 12px;
            }
        }
    }
}

.promoted_content_link {
    display: block;
    margin-bottom: 0;

    @include is-not-mobile {
        margin-bottom: 25px;
    }

    .promoted_content_img {
        display: block;
        width: 100%;
    }

    .promoted_content_headline {
        color: color(color-black);
        font-weight: 400;
        line-height: 26px;
        margin: 10px 0 10px 10px;

        @include is-not-mobile {
            line-height: 35px;
            margin: 10px 0;
        }
    }
}

promoted-content {
    margin: 0 0 25px 0;

    header {
        padding: 30px 10px 0;

        h2 {
            font-size: 42px;

            @include is-mobile {
                font-size: 20px;
                line-height: 40px;
            }
        }
    }

    h3 {
        margin-bottom: 15px;
    }

    promoted-content-story {
        display: block;
        margin: 0 0 20px;

        @include is-not-mobile {
            margin: 10px;

            &[mode="sidebar"] {
                margin: 0;
            }
        }

        @include is-mobile {
            &[mode="daily"] {
                margin: 0;
            }
        }
    }
}

.sm_video_unit {
    margin: 50px auto;
}

.has-scroll-x {
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; //sass-lint:disable-line no-misspelled-properties (Firefox-specific)

    &::-webkit-scrollbar { //sass-lint:disable-line no-vendor-prefixes
        display: none;
    }
}