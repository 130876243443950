@mixin gen-button {
    appearance: none;
    background: color(shakespeare);
    border-bottom: 4px solid color(shakespeare, -10);
    border-left: none;
    border-radius: 0;
    border-right: none;
    border-top: none;
    color: color(color-white);
    cursor: pointer;
    display: inline-block;
    font-family: $font-montserrat;
    font-size: 11px;
    line-height: normal;
    list-style: none;
    margin: 0 5px;
    padding: 4px 5px 3px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 -1px 0 color(color-black, 0, 0.25);
    text-transform: uppercase;
    width: auto;

    &.active,
    &:active {
        background: none repeat scroll 0 0 color(shakespeare);
        border-bottom: 2px solid color(shakespeare, -10);
        bottom: -2px;
        margin-top: 2px;
    }

    &.selected {
        background: color(color-white);
        border-bottom: medium none;
        color: color(shakespeare);
        text-shadow: none;
    }

    @content;
}

.gen-button-signup {
    @include gen-button {
        color: color(color-white);
        font-size: 12px;
        font-weight: 600;
        padding: 4px 10px 3px;

        &:active,
        &:focus,
        &:hover,
        &:visited {
            color: color(color-white);
            margin-top: -2px;
            text-decoration: none;
        }
    }
}

.gen-button-signup-alt {
    @include gen-button {
        background-color: color(red-violet);
        border-bottom-color: color(red-violet, -20);
        color: color(color-white);
        font-size: 12px;
        font-weight: 600;
        margin: 0;
        padding: 4px 10px 3px;

        &:active,
        &:focus,
        &:hover,
        &:visited {
            color: color(color-white);
            margin-top: -2px;
            text-decoration: none;
        }
    }
}

@mixin button {
    appearance: none;
    border-style: solid;
    border-width: 0 0 4px 0;
    color: color(color-white);
    cursor: default;
    display: inline-block;
    font-family: $font-montserrat;
    font-weight: 600;
    line-height: 1.5em;
    margin: 2px 0;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 -1px 0 color(color-black, 0, 0.25);
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
    }
}

.button,
.button-pink,
.button-pink-alternate,
.button-black,
.button-green,
.button-purple,
.button-crimson,
.button-warning {
    @include button;
    padding: 7px 14px;

    &:visited {
        color: color(color-white);
    }

    &:focus,
    &:hover {
        border-bottom-width: 2px;
        border-top: 2px solid color(color-white);
        color: color(color-white);
        text-decoration: none;
    }

    &:active {
        border-bottom-width: 0;
        border-top: 4px solid color(color-white);
        outline: none;
    }

    &.inactive,
    &.inactive:active,
    &.inactive:hover {
        opacity: 0.5;
    }

    &.disabled {
        background: color(silver-sand, 12);
        border-color: color(silver-sand);
        color: color(boulder, -1);
        cursor: not-allowed;
        font-weight: 600;

        &::after {
            border-left: 12px solid color(boulder);
        }

        &:active,
        &:focus,
        &:hover {
            border-bottom-width: 4px;
            border-top: none;
        }
    }

    > span.arrow {
        font-size: 12px;
        margin-left: 7px;
    }
}

.button {
    background: color(color-blue);
    border-bottom-color: color(wedgewood);
}

.button-warning,
.button-warning-simple {
    background: color(carrot-orange);
    border-color: color(trinidad);
}

.button-warning-alternate {
    appearance: none;
    background-color: color(color-white);
    border: solid 1px color(carrot-orange);
    color: color(carrot-orange) !important; //sass-lint:disable-line no-important
    display: inline-block;
    font-family: $font-montserrat;
    line-height: 1.5em;
    margin: 2px 0;
    padding: 7px 12px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 -1px 0 color(color-black, 0, 0.25);
    text-transform: uppercase;

    &:hover {
        border: solid 1px color(carrot-orange);
        color: color(carrot-orange);
        text-decoration: none;
        text-shadow: none;
    }
}

.button,
.button-warning,
.button-warning-alternate {
    font-size: 14px;
}

.button-pink,
.button-sidebar {
    background-color: color(red-violet);
    border-bottom-color: color(red-violet, -20);
    border-radius: 5px;
}

.button-black {
    background-color: color(color-black);
    border-color: color(color-silver);
}

.button-pink-alternate {
    background-color: color(cerise);
    border-bottom-color: color(lipstick);
}

.button-pink-reverse {
    background-color: color(color-white);
    border: 1px solid color(cerise) !important; //sass-lint:disable-line no-important
    color: color(cerise);
    text-shadow: none;

    &:visited {
        color: color(cerise);
    }
}

.button-green {
    background-color: color(shamrock);
    border-bottom-color: color(shamrock, -20);
    border-radius: 5px;
}

.button-purple {
    background-color: color(vivid-violet);
    border-color: color(seance);
}

.button-purple-alternate {
    background-color: color(daisy-bush);
    border-color: color(daisy-bush, -20);
}

.button-crimson {
    background-color: color(color-crimson);
    border-color: color(color-black);
}

.button-cerulean {
    background-color: color(cerulean);
    border-color: color(cerulean, -20);
}

.button-trinidad {
    background-color: color(trinidad);
    border-color: color(trinidad, -20);
}

@mixin new-button($color) {
    align-items: center;
    appearance: none;
    background: color($color);
    border: none;
    border-bottom: 4px solid color($color, -20%);
    border-radius: 3px;
    color: color(color-white);
    cursor: pointer;
    display: inline-flex;
    font-family: $font-montserrat;
    font-size: 18px;
    justify-content: center;
    padding: 15px 30px;
    position: relative;
    text-align: center;
    user-select: none;
    vertical-align: top;
    white-space: nowrap;

    &:focus,
    &:hover {
        background: color($color, -10%);
        border-bottom: 4px solid color($color, -30%);
        color: color(color-white);
        text-decoration: none;
    }

    &:active {
        border-bottom: none;
        margin-top: 4px;
    }

    &.has-arrow::after {
        border-bottom: 8px solid transparent;
        border-left: 8px solid color(color-white);
        border-top: 8px solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        margin-left: 8px;
        width: 0;
    }
}

.button-havelock-blue {
    @include new-button(havelock-blue);
}

.button-cerise {
    @include new-button(cerise);
}

.button-daisy-bush {
    @include new-button(daisy-bush);
}
