@import "_settings/colors";
@import "_settings/media-queries";

@font-face {
    font-display: swap;
    font-family: "TarotDotCom";
    src: local("TarotDotCom"), url("https://gfx.tarot.com/fonts/tarotdotcom.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/tarotdotcom.woff") format("woff");
    unicode-range: U+003f, U+211E, U+2160-216B, U+2297, U+25A1, U+25B3, U+2601, U+2603, U+2609-260D, U+263D-2653, U+26B3-26B9, U+26BB, U+2BD3, U+2BF2, U+01f701-01F704, U+10FFF0-10FFF8, U+1D403;
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-100-normal.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-100-normal.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 100;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-100-italic.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-100-italic.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 200;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-200-normal.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-200-normal.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 200;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-200-italic.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-200-italic.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-300-normal.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-300-normal.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 300;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-300-italic.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-300-italic.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-400-normal.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-400-normal.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-400-italic.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-400-italic.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-500-normal.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-500-normal.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 500;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-500-italic.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-500-italic.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-600-normal.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-600-normal.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 600;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-600-italic.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-600-italic.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-700-normal.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-700-normal.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    src: url("https://gfx.tarot.com/fonts/montserrat-latin-700-italic.woff2") format("woff2"), url("https://gfx.tarot.com/fonts/montserrat-latin-700-italic.woff") format("woff");
}

$font-default: Helvetica, Arial, sans-serif;
$font-baskerville: "Baskerville", "Times New Roman", serif;
$font-montserrat: "Montserrat", sans-serif;

@mixin disclosure-triangle {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid color(alto);
    content: "";
    float: right;
}

@each $name, $color in $colors {
    .has-text-#{$name} {
        color: #{$color};
    }

    .is-background-#{$name} {
        background-color: #{$color};
    }
}

textarea {
    appearance: none;
    background: transparent;
    border: 1px solid color(alto);
    border-radius: 5px;
    box-shadow: inset 0 1px 2px color(color-black, 0, 0.1);
    color: color(color-black);
    display: block;
    font-family: $font-montserrat;
    font-size: 24px;
    font-weight: 400;
    height: 45px;
    margin: 5px 0;
    padding: 7px;
    transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
    width: calc(100% - 10px);

    &:focus {
        background-color: color(alabaster);
        border-color: color(dusty-gray);
        box-shadow: 0 0 5px color(dusty-gray);
        outline: none;
    }

    &[disabled] {
        background-color: color(gallery);
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 24px;
    width: 100%;

    thead,
    tbody {
        th {
            border: none;
            padding: 8px 12px;
            text-align: left;
            vertical-align: middle;
        }
    }

    tbody {
        td {
            border: none;
            padding: 8px 12px;
            text-align: left;
            vertical-align: top;
        }
    }

    &.is-bordered {
        thead {
            tr {
                &:last-child {
                    th {
                        border-bottom-width: 1px;
                    }
                }
            }
        }

        td,
        th {
            border: 1px solid color(alto);
        }
    }
}

article {
    header {
        border-bottom: 1px solid color(alto);
        margin: 0 0 15px;
        padding: 0;
    }

    a {
        &.article-author {
            display: block;
            margin: 0 0 13px;
        }
    }

    .links-list {
        margin: 5px 0 25px;
    }

    span {
        &.article-date {
            font-weight: 400;
        }
    }

    small {
        color: color(silver-sand);
        display: block;
        font-size: 10px;
        text-align: center;
    }

    h3,
    .is-h3 {
        margin: 0 0 20px;
    }

    h5,
    .is-h5 {
        &.subtitle {
            color: color(boulder);
            margin: 20px 0;
        }
    }

    ul {
        font-size: 17px;
        line-height: 28px;
        margin: 0 0 20px 15px;

        @include is-mobile {
            margin-bottom: 0;
        }
    }

    ol {
        font-size: 17px;
        line-height: 28px;
        margin-left: 15px;
    }

    .psychic-tip {
        @include is-mobile {
            display: block;
            margin-left: -10px;
            margin-right: -10px;
        }
    }
}

.psychic-banner {
    background-color: color(eminence, 0, 0.1);//color(concrete);
    padding: 20px 0;

    @include is-mobile {
        font-size: 15px;
        line-height: 24px;
        padding: 10px 10px;
    }
}

.psychic-score {
    background-color: color(snuff);
    border-radius: 10px;
    color: color(eminence);
    font-size: 25px;
    font-weight: 500;
    padding: 15px;

    @include is-mobile {
        border-radius: 24px;
        font-size: 20px;
        padding: 10px;
    }
}

.psychic-button {
    margin-left: -20px;

    @include is-mobile {
        margin: 20px 15px 0 15px;
    }

    a {
        margin: auto;
        padding: 15px 20px;
        text-align: center;
        width: 100%;
    }
}

sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    top: -0.5em;
    vertical-align: baseline;
}

hr {
    border: none;
    border-top: 1px solid color(alto);
    margin: 15px 0;
}

img {
    &.glyph {
        display: block;
        width: 40%;
        @include is-mobile {
            width: 30%;
        }
    }

    &.pic_link {
        max-width: 95%;
    }

    &.icon {
        margin: auto auto 15px;
        max-height: 100px;
        width: 45%;
        @include is-mobile {
            width: 55%;
        }
    }

    &.flip {
        transform: rotate(180deg);
    }

    &.node-article-image {
        float: right;
        max-width: 165px;
        padding-left: 25px;
        @include is-mobile {
            max-width: 125px;
        }
    }

    &.bio-image {
        height: 45px;
        width: 45px;
    }

    &.mobile-bag {
        display: block;
        margin: 0 auto;
        width: 70%;
    }

    &.card_pic {
        display: inline-block;
        float: left;
        margin-right: 25px;
        @include is-mobile {
            width: 115px;
        }
    }
}

aside {
    &.related-nodes {
        column {
            padding: 0 10px;
        }

        h1,
        .is-h1 {
            margin-left: 10px;
        }
    }
}

.is-error {
    color: color(color-red);
    font-size: 12px;
    line-height: 19px;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.is-message {
    background-color: color(whisper);
    border-radius: 5px;
    color: color(eminence);
    padding: 5px;
    text-align: center;
}

input {
    &[type="search"] {
        //sass-lint:disable no-ids
        &:not(#searchsite) {
            background-position: 98%;
            background-size: 25px;
            border-radius: 13px;
            font-size: 15px;
            padding: 7px;
        }
        //sass-lint:enable no-ids

        &::placeholder {
            font-family: $font-montserrat;
            font-weight: 400;
        }

        //sass-lint:disable no-vendor-prefixes
        &::-webkit-search-cancel-button,
        &::-webkit-search-decoration {
            -webkit-appearance: none;
        }
        //sass-lint:enable no-vendor-prefixes

        &:focus {
            background-color: color(alabaster);
            border-color: color(dusty-gray);
            box-shadow: 0 0 5px color(dusty-gray);
            outline: none;
        }
    }
}

select-group .is-error,
input-group .is-error,
textarea-group .is-error,
toggle-group .is-error {
    bottom: 1px;
    position: absolute;
}

.is-error-multiline {
    color: color(color-red);
    font-size: 12px;
    line-height: 19px;
}

.has-error-multiline {
    margin-bottom: 40px;
}

.is-success {
    color: color(forest-green);
    font-size: 12px;
    line-height: 19px;
}

.has-text-tarotdotcom {
    font-family: "TarotDotCom";
}

.has-text-strikethrough {
    text-decoration: line-through;
}

.is-margin-auto {
    margin: auto;
}

.is-height-63 {
    height: 63px;
}

.is-height-auto {
    height: auto;
}

.is-pill {
    border: none !important; //sass-lint:disable-line no-important
    border-radius: 10px;
    color: color(color-white);
    cursor: pointer;
    display: inline-block;
    padding: 18px 15px;
    position: relative;
    text-align: center;
    text-decoration: none;

    @include is-mobile {
        display: block;
    }

    &:hover {
        color: color(color-white);
        cursor: pointer;
        text-decoration: none;

        &.button-purple {
            background-color: color(color-white);
            color: color(vivid-violet);
            outline: 1px solid color(vivid-violet);
            text-shadow: none;
        }
    }

    &.pill-arrow::after {
        border-bottom: 7px solid transparent;
        border-left: 7px solid color(color-white);
        border-right: 0 solid transparent;
        border-top: 7px solid transparent;
        top: 21px;
    }
}

.environment-tab {
    background-color: color(lavender-purple);
    bottom: 200px;
    color: color(color-white);
    font-family: $font-montserrat;
    font-weight: 400;
    padding: 10px 8.5px;
    position: fixed;
    right: 0;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    z-index: 10000;
}

[tooltip] {
    cursor: help;
    position: relative;
    z-index: 3;

    &::after {
        background: color(color-black, 0, 0.7);
        border-radius: 5px;
        color: color(color-white);
        content: attr(tooltip);
        display: none;
        font-size: 12px;
        left: 0%;
        margin-left: -8px;
        padding: 4px;
        pointer-events: none;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateX(-100%) translateY(-50%);
        width: 200px;
    }

    &::before {
        border-color: color(color-black, 0, 0.7) transparent transparent transparent;
        border-style: solid;
        border-width: 4px 6px 0 6px;
        content: "";
        display: none;
        left: 0%;
        margin-left: -12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
    }

    &:hover {
        &::after,
        &::before {
            display: block;
        }
    }

    &[position="right"] {
        &::after {
            left: 100%;
            margin-left: 8px;
            top: 50%;
            transform: translateX(0%) translateY(-50%);
        }

        &::before {
            left: 100%;
            margin-left: 1px;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }
}

.has-list-style-none {
    list-style: none;
}

.has-list-style-type-decimal {
    list-style-type: decimal;
}

.has-cursor-pointer:hover {
    cursor: pointer;
}

@include is-tiny {
    .is-hidden-tiny {
        display: none !important; //sass-lint:disable-line no-important
    }

    @for $s from 1 through 12 {
        column.is-#{$s}-tiny {
            width: calc(($s / 12) * 100%) !important; //sass-lint:disable-line no-important
        }
    }
}

.has-text-uppercase {
    text-transform: uppercase;
}

.has-margin--10-bottom {
    margin-bottom: -10px;
}

.has-margin--10-sides {
    margin: 0 -10px;
}

@import "_partials/buttons";
@import "_partials/misc";
@import "_partials/nav";
@import "_partials/footer";

@import "_pages/daily";
@import "_pages/full-width";
@import "_pages/product";
@import "_pages/receipt";
@import "_pages/search";

@import "_components/astrology-compatibility";
@import "_components/birthday-select";
@import "_components/compatibility-chooser";
@import "_components/date-picker";
@import "_components/date-select";
@import "_components/form/autocomplete-group";
@import "_components/form/checkbox-group";
@import "_components/form/input-group";
@import "_components/form/radio-group";
@import "_components/form/select-group";
@import "_components/form/textarea-group";
@import "_components/form/toggle-group";
@import "_components/karmacoin-store";
@import "_components/modals/login";
@import "_components/modals/password";
@import "_components/modals/signup";
@import "_components/modals/sms";
@import "_components/nav/articles";
@import "_components/nav/dropdowns";
@import "_components/nav/vip";
@import "_components/nav/panel";
@import "_components/product-carousel";
@import "_components/promo-message";
@import "_components/subscription-teeup";
@import "_components/user-menu";
@import "_components/vip/transits-list";
@import "_components/zodiac-sign-picker";
