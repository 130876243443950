subscription-teeup {
    display: block;
    margin-bottom: 50px;
    position: relative;

    @include is-not-mobile {
        padding: 0 125px;
    }

    > img:first-child {
        aspect-ratio: auto 1200 / 160; //sass-lint:disable-line no-misspelled-properties

        @include is-mobile {
            aspect-ratio: auto 380 / 104; //sass-lint:disable-line no-misspelled-properties
        }
    }

    &[upc="forecast-monthly-subscription"] {
        padding: 0;

        h1 {
            font-size: 59px;
            line-height: 65px;

            @include is-mobile {
                font-size: 49px;
                line-height: 49px;
            }
        }
    }

    .icon-teeup {
        background-image: url("https://gfx.tarot.com/images/site/tarot/daily-reflection-tarot-redux/icon.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        height: 45px;
        margin: 0 auto;
        max-width: 25%;

        @include is-not-mobile {
            height: 50px;
            margin-top: 5px;
        }
    }

    .price {
        color: color(silver-sand);

        @include is-not-mobile {
            position: absolute;
            right: 125px;
        }

        @include is-mobile {
            text-align: center;
        }
    }

    header {
        margin: 0 auto 30px;
        text-align: center;

        p.details {
            text-align: left;
        }

        @include is-mobile {
            max-width: 350px;
        }

        @include is-tiny {
            max-width: 300px;
        }
    }
}

.vip-content-container {
    margin: 0 auto;
    max-width: 1100px;

    row:first-child column {
        max-width: 387px;
    }

    h3,
    .is-h3 {
        font-size: 25px;
        line-height: 30px;

        @include is-mobile {
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 10px;
        }
    }

    li {
        font-size: 16px;
        line-height: 30px;

        @include is-mobile {
            font-size: 12px;
            line-height: 17px;
            margin-bottom: 10px;
        }
    }

    .is-carousel {
        align-content: center;
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin: 5px;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 10px;
        position: relative;
        scrollbar-width: none; //sass-lint:disable-line no-misspelled-properties (Firefox-specific)

        &::-webkit-scrollbar { //sass-lint:disable-line no-vendor-prefixes
            display: none;
        }
    }

    .is-carousel-card {
        border: 1px solid color(color-black);
        border-radius: 15px;
        display: block;
        margin: 0 5px 20px;
        min-width: 180px;
        padding: 15px 8px 20px;
    }
}

main.expanded {
    margin: -1px auto 0;
    padding: 0;
    width: 100%;
}
