login-modal {
    dialog-content {
        input-group {
            @include is-mobile {
                input {
                    font-size: 20px;
                }
            }
        }

        a {
            bottom: 8px;
            font-size: 11.2px;
            left: 20px;
            line-height: 14px;
            position: absolute;
            text-align: center;

            @include is-mobile {
                bottom: 0;
                left: 11px;
            }
        }

        button {
            @include gen-button;
            background-color: color(carrot-orange);
            border-color: color(trinidad);
            font-size: 14px;
            font-weight: 600;
            height: 40px;
            min-width: 205px;
            padding: 7px 14px;

            @include is-mobile {
                min-width: 140px;
            }

            &:active {
                background-color: color(carrot-orange);
            }
        }

        .confirm_row {
            display: none;
            height: 0;
            margin: 0;
            opacity: 0;
            overflow: hidden;
            padding: 0;
            visibility: hidden;
        }
    }
}
