input-group {
    display: block;
    height: 77px;
    position: relative;
    transform-style: preserve-3d;

    &.has-label {
        height: 88px;

        .input-group-placeholder {
            top: 25px;
        }
    }

    &.has-error {
        input {
            border-color: color(color-red) !important; //sass-lint:disable-line no-important
        }
    }

    input {
        appearance: none;
        background: transparent;
        border: 1px solid color(alto);
        border-radius: 5px;
        box-shadow: inset 0 1px 2px color(color-black, 0, 0.1);
        color: color(color-black);
        display: block;
        font-family: $font-montserrat;
        font-size: 24px;
        font-weight: 400;
        height: 45px;
        margin: 5px 0;
        padding: 7px;
        transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
        width: 100%;

        &[type="password"] {
            @include is-mobile {
                font-size: 14px;
            }
        }

        &:focus {
            background-color: color(alabaster);
            border-color: color(dusty-gray);
            box-shadow: 0 0 5px color(dusty-gray);
            outline: none;
        }

        &[disabled] {
            background-color: color(gallery);
            cursor: not-allowed;
        }

        &::placeholder {
            display: none !important; //sass-lint:disable-line no-important
        }
    }

    .input-group-placeholder {
        align-items: center;
        color: color(boulder);
        display: flex;
        font-size: 11px;
        height: 45px;
        left: 8px;
        position: absolute;
        z-index: -1;
    }
}