vip-transits-list,
panel-module-transits {
    display: flex;
    flex-direction: column;

    > a {
        border-top: 1px solid color(alto);
        padding: 15px 40px 20px 20px;
        position: relative;

        &:last-child {
            border-bottom: 1px solid color(alto);
        }

        &::after {
            border-bottom: 10px solid transparent;
            border-left: 10px solid color(alto);
            border-top: 10px solid transparent;
            content: "";
            height: 7px;
            margin-top: -7px;
            position: absolute;
            right: 20px;
            top: 50%;
            width: 7px;
        }

        &:hover {
            background-color: color(whisper);
            text-decoration: none;
        }

        h5 {
            color: color(boulder);
            margin-bottom: 10px;

            .symbol {
                font-family: "TarotDotCom";
                font-size: 20px;
                font-style: normal;
            }
        }

        h2,
        p:nth-child(2) {
            color: color(color-black);
            margin-bottom: 15px;
        }

        @include is-mobile {
            h5 {
                font-size: 14px;
            }

            h2,
            p:nth-child(2) {
                font-size: 18px;
                font-weight: 400;
            }

            span.transit-label {
                font-size: 12px;
            }
        }
    }
}

.transit-label {
    background-color: color(color-white);
    border: 1px solid;
    border-radius: 5px;
    display: inline-block;
    padding: 5px;

    &.mood {
        border-color: color(affair);
        color: color(affair);

        &:hover {
            color: color(affair);
        }
    }

    &.communication-and-information {
        border-color: color(shakespeare);
        color: color(shakespeare);

        &:hover {
            color: color(shakespeare);
        }
    }

    &.love-and-relationships {
        border-color: color(maroon-flush);
        color: color(maroon-flush);

        &:hover {
            color: color(maroon-flush);
        }
    }

    &.action-and-initiative {
        border-color: color(jungle-green);
        color: color(jungle-green);

        &:hover {
            color: color(jungle-green);
        }
    }

    &.opportunities {
        border-color: color(fruit-salad);
        color: color(fruit-salad);

        &:hover {
            color: color(fruit-salad);
        }
    }

    &.challenges-and-responsibilities {
        border-color: color(daisy-bush);
        color: color(daisy-bush);

        &:hover {
            color: color(daisy-bush);
        }
    }

    &.surprises-and-breakthroughs {
        border-color: color(bahama-blue);
        color: color(bahama-blue);

        &:hover {
            color: color(bahama-blue);
        }
    }

    &.inspiration-and-transformation {
        border-color: color(lipstick);
        color: color(lipstick);

        &:hover {
            color: color(lipstick);
        }
    }
}
