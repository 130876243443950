daily-number {
    display: block;
    min-height: 260px;
}

daily-hexagram {
    display: block;
    min-height: 650px;

    h3 {
        @include is-mobile-and-tablet-portrait {
            font-size: 17px;
            line-height: 28px;
        }
    }
}

daily-tarot-card {
    display: block;

    img {
        width: 100%;
    }

    small {
        line-height: 13px;
        margin-top: 5px;
    }

    h3 {
        @include is-mobile-and-tablet-portrait {
            font-size: 17px;
            line-height: 28px;
        }
    }

    .tcod-pills {
        @include is-mobile {
            display: flex;
            flex-wrap: wrap;
            height: 100px;
            overflow-x: scroll;
            overflow-y: hidden;
        }
    }

    .daily-tarot-card {
        float: left;
        margin-right: 20px;

        img {
            display: block;
            margin: 0 auto;

            @include is-not-mobile {
                width: 225px;
            }

            &.tarot-card-unknown {
                @include is-not-mobile {
                    height: 200px;
                }
            }
        }

        small {
            line-height: 13px;
            margin-top: 5px;
        }
    }

    .daily-tarot-card-mobile {
        img {
            display: block;
            margin: 0 auto 20px;
            max-width: 225px;
        }
    }
}

article {
    &.daily-psychic-tip {
        @include is-mobile {
            margin: -20px 0 0 0;
        }

        @include is-not-mobile {
            margin-bottom: 25px;
        }

        img {
            width: 100%;
        }

        h1 {
            margin: 10px 10px 15px 10px;
            text-align: left;

            @include is-not-mobile {
                margin: 110px 0 0 25px;
            }
        }

        h3 {
            color: color(red-violet);
            margin: 20px 10px;

            @include is-not-mobile {
                margin-left: 0;
            }
        }

        p {
            @include is-mobile {
                margin: 0 10px 20px 10px;
            }
        }
    }

    div.daily-hexagram {
        float: left;
        margin-right: 20px;

        img {
            display: block;
            margin: 0 auto 10px auto;

            @include is-not-mobile {
                width: 225px;
            }

            &.hexagram-unknown {
                @include is-not-mobile {
                    height: 150px;
                }
            }
        }
    }

    div.daily-number {
        float: left;
        margin: 0 20px 0 0;

        img {
            display: block;
            margin: 0 auto;
            width: 165px;

            &.number-unknown {
                @include is-not-mobile {
                    height: 200px;
                    width: auto;
                }
            }
        }
    }

    daily-number-mobile {
        display: block;
        margin: 20px 10px 0 10px;

        img {
            display: block;
            margin: 0 auto 20px auto;
            max-width: 225px;
        }

        .today-number {
            height: 320px;
            overflow: hidden;
        }

        @for $number from 1 through 9 {
            .nod-#{$number} {
                background: url("https://gfx.tarot.com/images/numerology/daily/#{$number}.jpg") no-repeat 50% 100%;
            }
        }

        h2 {
            margin: 10px 0 15px 0;
        }

        h5 {
            margin-bottom: 10px;
        }
    }

    daily-hexagram-mobile {
        img {
            display: block;
            margin: 20px auto;
            max-width: 225px;
        }
    }
}

.daily-sign-content {
    ul {
        list-style: none;
        margin: -5px 0 20px 0;

        @include is-mobile {
            margin: -5px 10px 20px 10px;
        }

        li {

            a {
                display: inline-block;
                line-height: 28px;
                margin-right: 10px;
            }
        }
    }
}

daily-pills {
    display: block;
    margin: 10px -11px 10px 0;
    max-width: 700px;
    min-height: 92px;

    @include is-mobile-and-tablet-portrait {
        overflow: scroll;
        width: 100%;
    }
}

.pill {
    border: 1px solid color(cerise);
    border-radius: 7px;
    color: color(cerise);
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin: 6px 3px;
    padding: 9px;
    position: relative;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
        background-color: color(cerise);
        color: color(color-white);
        text-decoration: none;
    }

    &.reverse-pill {
        background-color: color(cerise);
        color: color(color-white);

        &:hover {
            background-color: color(color-white);
            color: color(cerise);
            text-decoration: none;
        }
    }
}

keen {
    display: block;
    margin: 0 0 25px;

    .online-now {
        bottom: 5px;
        height: 65px;
        position: absolute;
        right: 5px;
        width: 65px;
        @include is-mobile {
            bottom: -10px;
        }
    }

    .keen {
        border-radius: 5px;
        box-shadow: 0 3px 5px 3px color(alto);
        margin-bottom: 40px;
        padding-bottom: 40px;
        padding-top: 20px;

        @include is-mobile {
            padding-bottom: 20px;
        }
    }

    a {
        color: color(color-black);

        &:hover {
            text-decoration: none;
        }

        h3,
        p {
            overflow-wrap: break-word;
        }
    }

    img {
        object-fit: cover;
    }
}

keen-slider {
    content-visibility: auto; //sass-lint:disable-line no-misspelled-properties
    display: block;
    margin: 30px 0 30px;
    min-height: 260px;

    @include is-mobile {
        min-height: 520px;
    }

    > row {
        justify-content: space-between;
    }

    p {
        font-size: 11px;
        margin: 0;

        @include is-mobile {
            line-height: 13px;
        }

        a {
            color: color(vivid-violet);
        }
    }

    a.keen-listing-container {
        color: color(color-black);

        &:hover {
            text-decoration: none;
        }

        column:first-of-type {
            padding: 10px 5px 10px 10px;
        }

        .keen-image-container {
            max-width: 165px;
            position: relative;
        }

        img {
            &.keen-psychic {
                border-radius: 50%;

                &:hover {
                    opacity: 0.8;
                }
            }

            &.star {
                height: 15px;
                width: 20px;
            }
        }

        .dot {
            background-color: color(fruit-salad);
            border: 2px solid color(color-white);
            border-radius: 100%;
            height: 20px;
            padding: 0;
            position: absolute;
            right: 14%;
            top: 14%;
            transform: scale(1) translate(50%, -50%);
            width: 20px;
            z-index: 1;
        }

        p {
            &.psychic-name {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 15px;
                // min-height: 40px;
                overflow: hidden;
                overflow-wrap: break-word;
            }
        }

        button {
            background-color: color(color-black);
            display: block;
            font-family: "Montserrat", sans-serif;
            margin: 0 0 10px;
            padding: 10px 15px;
            transition: all 0.1s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .keen-listing-price-container {
        color: color(mine-shaft);
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 16px;
        margin-bottom: 8px;
        margin-left: 10px;

        .offer-text {
            background-color: color(snuff);
            color: color(eminence);
            display: inline-block;
            margin-right: 2px;
            padding: 3px 18px 5px 8px;
            position: relative;

            &::after {
                -webkit-transform: rotate(45deg); // sass-lint:disable-line no-vendor-prefixes
                background-color: color(color-white);
                content: "";
                height: 16px;
                position: absolute;
                right: -8px;
                top: 3px;
                transform: rotate(45deg);
                width: 16px;
            }
        }

        .listing-price {
            background-color: color(color-white);
            display: inline-block;
            position: relative;
            z-index: 1;

            &::after {
                border-top: 1px solid color(mine-shaft);
                content: "";
                left: 0;
                position: absolute;
                right: 0;
                top: 8px;
            }
        }
    }
}

.daily-links {
    &.rising {
        font-size: 14px;

        a {
            padding: 0;
            text-decoration: none;
        }

        .daily-symbol {
            font-family: "TarotDotCom";
            font-size: 35px;
            margin-right: 10px;
            margin-top: 7px;
        }
    }
}

.more-horoscopes {
    color: color(color-black);

    &:hover {
        text-decoration: none;

        p.read-more {
            text-decoration: underline;
        }
    }

    .daily-symbol {
        font-family: "TarotDotCom";
        font-size: 50px;

        @include is-mobile {
            font-size: 35px;
        }
    }

    p {
        display: inline;
        margin: 0;

        &.read-more {
            color: color(science-blue) !important; //sass-lint:disable-line no-important
            font-size: 17px;
        }
    }
}
