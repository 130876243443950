zodiac-sign-picker {
    display: block;

    @include is-mobile {
        margin: 0 auto 20px auto;
    }

    @include is-not-mobile {
        margin: 30px auto 0 auto;
    }

    .is-h4 {
        margin: 0 0 20px 10px;
    }

    p {
        margin: 0 0 0 10px;

        &.sign {
            font-size: 14.5px;
            line-height: 24px;
            margin: 0;
            text-transform: capitalize;
        }

        &.dates {
            font-size: 10px;
            line-height: 16.5px;
            margin: 0;
        }
    }

    .zodiac-signs {
        align-content: center;
        align-items: center;
        clear: both;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: -5px;

        .zodiac-sign {
            background-position: center 5px;
            background-repeat: no-repeat;
            background-size: 45px;
            border: 1px solid color(color-white);
            border-radius: 10px;
            color: color(color-white);
            font-size: 12px;
            margin: 5px 0 5px 0;
            padding: 40px 5px 8px 5px;
            text-align: center;
            width: 90px;

            @include is-tablet {
                width: calc(25% - 15px);
            }

            @include is-desktop {
                width: calc(16.66% - 15px);
            }

            @each $sign, $color in $zodiac-signs {
                &.#{$sign} {
                    background-color: $color;
                    background-image: url("https://gfx.tarot.com/images/daily-pages/glyphs/light-#{$sign}.svg");

                    &:active,
                    &:hover {
                        background-color: color(color-white);
                        background-image: url("https://gfx.tarot.com/images/daily-pages/glyphs/#{$sign}.svg");
                        border-color: $color;
                        color: $color;
                    }
                }
            }
        }
    }

    a {

        &.header {
            display: inline-block;
            margin: 10px 0 0 5px;
        }

        &.footer {
            display: block;
            margin: 20px 0 0 0;
            text-align: center;
        }
    }
}