$social: (
    "facebook",
    "instagram",
    "pinterest",
    "twitter",
    "youtube",
);

footer {
    background-color: color(eminence);
    color: color(boulder);
    font-family: $font-montserrat;
    font-size: 0.8rem;
    margin: 30px auto 0;

    &.show-nav,
    &.show-notify {
        display: none;
    }

    p {
        font-size: 12px;
        font-weight: inherit;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            font-size: 13px;
            line-height: 1.4;

            @include is-mobile {
                font-size: 11px;
            }
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &:active,
        &:focus,
        &:hover,
        &:link,
        &:visited {
            color: inherit;
        }
    }
}

// sass-lint:disable no-vendor-prefixes
.footer-social {
    background-color: color(whisper);
    margin: 0;
    text-align: center;

    @each $site in $social {
        a[href^="https://#{$site}.com"] {
            -webkit-mask-image: url("https://gfx.tarot.com/images/icons/footer/#{$site}-footer.svg");
            background-color: color(eminence);
            mask-image: url("https://gfx.tarot.com/images/icons/footer/#{$site}-footer.svg");
        }
    }

    ul {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 450px;
        padding: 15px;
    }

    li {
        display: inline-block;
        padding-right: 10px;
    }

    a {
        background-repeat: no-repeat;
        display: block;
        height: 25px;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        width: 25px;
    }
}
// sass-lint:enable no-vendor-prefixes

.footer-nav {
    margin: 0 auto;
    max-width: 1000px;
    padding-left: 5px;

    @include is-not-mobile {
        padding-left: 5%;
    }
}

.footer-info {
    background-color: color(color-white);

    @include is-not-mobile {
        padding-left: 5%;
    }

    > row {
        margin: 0 auto;
        max-width: 1000px;
    }

    small {
        display: block;
        font-size: 80%;
        margin: 0 0 12px;

        a {
            text-decoration: underline;
            text-underline-offset: 5px; //sass-lint:disable-line no-misspelled-properties
        }
    }
}

.faux-dropdown {
    background-color: color(color-white);
    border: 1px solid color(boulder);
    cursor: pointer;
    margin: 7px auto 0;
    padding: 2px 10px;
    position: relative;
    width: 225px;

    &:hover {
        background-color: color(boulder);
        transition: all 250ms;

        p {
            color: color(color-white);

            &::after {
                border-bottom: 7px solid color(color-white);
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: none;
            }
        }

        .faux-dropdown-items {
            display: block;
        }
    }

    .faux-dropdown-items {
        background-color: color(color-white);
        bottom: 35px;
        display: none;
        left: 0;
        position: absolute;
        width: 100%;

        @include is-mobile {
            bottom: 31px;
        }
    }

    p {
        color: color(boulder, -25);
        line-height: 2.5em;
        margin: 0;
        padding-right: 40px;
        text-transform: uppercase;

        @include is-not-mobile {
            color: color(boulder);
        }

        &::after {
            @include disclosure-triangle;
            float: none;
            position: absolute;
            right: 10px;
            top: 40%;
        }
    }

    a {
        border-left: 1px solid color(boulder);
        border-right: 1px solid color(boulder);
        border-top: 1px solid color(boulder);
        color: inherit;
        display: block;
        font-size: 12px;
        line-height: 2.5em;
        padding: 2px 10px;

        &:hover {
            background-color: color(eminence);
            color: color(color-white);
            transition: background-color 250ms;
        }
    }
}

.footer-tarot-links {
    a {
        margin: 0 10px 0 0;
    }

    @include is-mobile {
        column {
            display: flex;
            flex-direction: column;

            a {
                margin: 0 0 10px 0;
            }
        }
    }

}

.faux-dropdown h5,
.faux-dropdown li,
.footer-social h5,
.footer-tarot-links {
    font-family: $font-montserrat;
}