signup-modal {
    dialog-content {
        input-group {
            @include is-mobile {
                input {
                    font-size: 20px;
                }
            }
        }

        select-group {
            @include is-mobile {
                &::after {
                    right: 5px;
                }

                select {
                    font-size: 20px;
                }
            }
        }

        checkbox-group {
            margin: 0 0 10px 0;
        }

        button {
            @include gen-button;
            background-color: color(carrot-orange);
            border-color: color(trinidad);
            font-size: 14px;
            font-weight: 600;
            height: 40px;
            min-width: 205px;
            padding: 7px 14px;

            @include is-mobile {
                min-width: 140px;
            }

            &:active {
                background-color: color(carrot-orange);
            }

            &[disabled] {
                background: color(silver-sand, 12);
                border-color: color(silver-sand);
                color: color(boulder, -1);
                cursor: not-allowed;
            }
        }

        .agree_row {
            display: none;
            height: 0;
            margin: 0;
            opacity: 0;
            overflow: hidden;
            padding: 0;
            visibility: hidden;
        }

        a {
            color: color(color-black);
            text-decoration: underline;
        }

        .birthdate-error {
            margin: -20px 0 5px 0;
        }
    }
}