nav-articles {
    margin-right: 10px;
    width: 450px;

    @include is-mobile-and-tablet-portrait {
        margin: 0;
        width: auto;
    }

    > a {
        color: color(trout);
        font-family: $font-baskerville;
        font-size: 26px;
        font-style: italic;
        font-weight: 500;
        line-height: 36px;
        margin: 2.8px 0 7px 0;

        &:hover {
            color: color(color-black);
        }
    }

    > row {
        margin-top: 7px;
    }

    p {
        color: color(eminence);
        font-size: 15px;
        font-weight: 500;

        @include is-desktop-and-tablet-landscape {
            color: color(trout);
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            margin: 0 10px 10px 15px;
        }
    }

    a:hover p {
        text-decoration: underline;
    }
}