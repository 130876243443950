radio-group {
    display: inline-block;
    height: 25px;
    margin: 0 10px 0 0;

    pseudo-radio {
        border: solid 1px color(boulder);
        border-radius: 25px;
        display: inline-block;
        height: 25px;
        margin: 0 5px -7px 5px;
        position: relative;
        width: 25px;

        span {
            background-color: color(boulder);
            border-radius: 17px;
            display: none;
            height: 17px;
            left: 3px;
            position: absolute;
            top: 3px;
            width: 17px;
            z-index: 1;
        }

        &.checked {
            span {
                display: block;
            }
        }

        &.disabled {
            background-color: color(gallery);
            border-color: color(alto);
            cursor: not-allowed;

            span {
                background-color: color(alto);
                cursor: not-allowed;
            }
        }

        input {
            bottom: 0;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 2;
        }
    }
}