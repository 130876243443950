checkbox-group {
    display: block;
    margin: 10px 0;

    pseudo-checkbox {
        border: solid 1px color(boulder);
        border-radius: 5px;
        display: inline-block;
        height: 25px;
        position: relative;
        width: 25px;

        &.has-error {
            border-color: color(color-red);
        }

        span {
            left: 4.5px;
            position: absolute;
            top: 3px;
            z-index: 1;

            &::before {
                content: "";
                font-size: 20px;
            }
        }

        &.checked {
            span::before {
                content: "\2713";
            }
        }

        &.disabled {
            background-color: color(gallery);
            border-color: color(alto);
            cursor: not-allowed;

            span {
                cursor: not-allowed;

                &::before {
                    color: color(alto);
                }
            }
        }

        input {
            bottom: 0;
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: -3px;
            width: 100%;
            z-index: 2;
        }
    }

    raw {
        display: inline-block;
        min-height: 25px;
    }

    label {
        /* sass-lint:disable no-important */
        small {
            color: color(boulder) !important;
            display: inline-block !important;
            font-size: 12px !important;
            text-align: left !important;
            vertical-align: top;
        }
        /* sass-lint:enable no-important */
    }
}