.user-li-nav {
    background-color: color(color-white);
    list-style: none;

    p {
        margin: 0;
    }

    a {
        display: flex;

        span {
            flex: 0 0 auto;
        }
    }

    h5 {
        color: color(color-black);
    }


    li {
        border-top: 1px solid color(eminence, 0, 0.1);
        padding: 10px 2px 10px 5px;

        h5 {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 1px 0 0;
        }

        &:hover {
            background-color: color(whisper);
            transition: background-color 200ms;
        }

        p {
            color: color(eminence);
            font-size: 11px;
            line-height: 12px;
            margin: 0;
            text-transform: capitalize;

            @include is-tablet-landscape {
                font-size: 10px;
            }
        }

        &:first-of-type {
            display: list-item;
        }
    }

    $link-icons: logout, vip, journal, karmacoins, settings, wheel;
    @each $icon in $link-icons {
        .ub-#{$icon} span {
            background-image: url("https://gfx.tarot.com/images/navigation/icons/#{$icon}.svg");
            background-repeat: no-repeat;
            display: inline-block;
            height: 40px;
            vertical-align: middle;
            width: 41px;
        }
    }

    .ub-vip {
        span {
            height: 35px;
            margin: 0 2px 0 0;
            width: 40px;
        }
    }

    .ub-karmacoins {
        span {
            height: 38px;
            margin: 0 3px 0 -3px;
        }
    }

    .ub-journal,
    .ub-settings {
        span {
            margin: 0 4px 0 -4px;
        }
    }

    .ub-wheel {
        span {
            height: 35px;
        }
    }

    .ub-logout {
        a {
            align-items: center;
            justify-content: center;
            padding-right: 20%;
            text-align: center;
        }

        span {
            height: 30px;
            width: 30px;

            @include is-tablet-portrait {
                height: 23px;
            }
        }

        .account-details h5 {
            font-size: 13px;
            font-weight: 300px;
            margin: 0 0 3px;

            @include is-tablet-portrait {
                font-size: 12px;
            }
        }
    }
}

.user-header {
    height: 100%;
    padding: 12px 0 12px 9px;

    .avatar-mask {
        margin-right: 12px;
        top: 0;
    }

    .my-account {
        border-top: none;
        color: color(eminence);
        display: inline-block;
        vertical-align: middle;

        span {
            @include is-mobile-and-tablet-portrait {
                color: color(color-white);
            }
        }

        h5 {
            color: color(color-white);
            line-height: 22px;
        }

        p {
            color: color(color-white);
            font-size: 10px;
            line-height: 10px;
            margin: 0;

            @include is-tiny {
                font-size: 9px;
            }

            &::before {
                background-image: url("https://gfx.tarot.com/images/site/topbar/kc.svg");
                background-repeat: no-repeat;
                content: "";
                display: inline-block;
                height: 11px;
                vertical-align: text-bottom;
                width: 13px;
            }
        }
    }
}

@include is-mobile {
    .my-account {
        height: auto;
        padding: 0;
    }

    .user-li-nav {
        li {
            &:first-of-type {
                border: none;
            }

            &.ub-logout {
                border-bottom: 1px solid color(gallery);
            }

            a {
                padding-left: 5px;

                .account-details {
                    margin-left: 5px;
                }

                .is-h5 {
                    line-height: normal;
                }

                p {
                    font-size: 12px;
                }
            }
        }
    }
}

user-menu {
    .user-li {
        .user-li-nav {
            display: block;
            min-width: 224px;
            overflow: hidden;
            position: absolute;
            right: -5px;
            top: 68px;
            z-index: 21;
        }

        .my-account:hover ~ .user-li-nav,
        .user-li-nav:hover {
            background-color: color(color-white, 0, 0.98);
            box-shadow: -3px 15px 15px 0 color(color-black, 0, 0.07);
            max-height: 420px;
        }
    }
}