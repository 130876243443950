@mixin skeleton {
    animation: loading 2s infinite;
    background-image: linear-gradient(
        90deg,
        color(alto, 1, 0) 0,
        color(alto, 1, 0.8) 50%,
        color(alto, 1, 0) 100%
    );

    background-position: -300px 0;
    background-repeat: no-repeat;
    background-size: 100%;
    content: "";
    display: inline-block;
    height: 100%;
    min-height: inherit;
    padding: 2px;
    width: 100%;

    @keyframes loading {
        to {
            background-position: 300px 0;
        }
    }
}

.skeleton:empty::before {
    @include skeleton;
}

.skeleton-child > :first-child {
    height: 100%;

    &:empty::before {
        @include skeleton;
    }
}

nav-panel {
    background-color: color(color-white);
    border: 1px solid color(eminence);
    border-radius: 15px 15px 0 0;
    bottom: 0;
    box-shadow: 0 10px 45px color(mine-shaft, 1, 0.2);
    display: none;
    left: 2.5vw;
    margin: 0 auto;
    position: fixed;
    top: 2.5vh;
    width: 95vw;
    z-index: 6000000;

    &.show-nav {
        display: block;
    }

    @include is-not-mobile {
        display: none !important; //sass-lint:disable-line no-important
    }

    p.is-h2 > a.is-h5 {
        font-size: 11px;
        font-style: normal;
        margin-left: 20px;
    }

    > button {
        background: transparent;
        border: none;
        color: color(color-black, 0, 0.2);
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
        height: 20px;
        line-height: 20px;
        outline: none;
        padding: 0;
        position: absolute;
        right: 15px;
        text-align: right;
        text-shadow: 0 1px 0 color(color-white);
        top: 10px;
        width: 13px;
    }
}

div.panel-overlay.show-nav {
    background-color: color(eminence, 0, 0.7);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transition: background-color 200ms ease-in-out;
    width: 100vw;
    z-index: 5999999;

    @include is-not-mobile {
        display: none;
    }
}

panel-loader {
    display: block;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 187px;
    position: relative;

    .panel-subheader {
        font-size: 22px;
        font-weight: 400;
        line-height: 30px;
        margin: 10px 15px;
    }

    .button.is-pill {
        padding: 14px;
    }

    panel-module-daily-links img,
    panel-module-love-links img {
        display: block;
        height: 35px;
        width: 35px;
    }

    panel-account {
        .account-settings-header {
            border-top: 1px solid color(alto);
            color: color(color-black);
            font-size: 18px;
            font-weight: 400;
            line-height: 35px;
            margin: 0;
            padding: 10px 15px;

            &:last-of-type {
                border-bottom: 1px solid color(gallery);
            }
        }

        panel-module-journal {
            tile-view {
                display: block;
                height: 375px;
                padding: 0 10px;

                tile-view-item {
                    height: 360px;
                    margin: 0 10px;
                    opacity: 0;
                    padding: 5px 15px 35px;
                    width: 230px;

                    p {
                        margin: 0;
                    }

                    div.v-center.tarot {
                        height: 225px;
                    }

                    > row:last-of-type {
                        bottom: 7px;
                    }
                }

                column:only-of-type > tile-view-item {
                    margin: 0 auto 15px;
                }
            }
        }

        panel-module-userbar {
            > ul.user-li-nav > li > a {
                padding-left: 15px;
            }

            .ub-logout,
            .ub-wheel {
                display: none;
            }
        }
    }

    panel-module-promo {
        display: block;
        flex: 1 1 auto;

        &:empty::before {
            @include skeleton;
        }

        &[placement="product-carousel"] {
            margin-top: 15px;
            min-height: 252px;

            product-carousel {
                margin: 0;
                padding: 0 10px;
            }
        }

        &[placement="featured-spread"] {
            min-height: 210px;
        }
    }

    panel-module-birth-chart  {
        display: block;
        flex: 1 1 auto;
        min-height: 495px;

        tooltip > icon {
            font-size: 13px;
            height: 18px;
            vertical-align: baseline;
            width: 18px;
        }
    }

    panel-tarot {
        display: flex;
        flex-direction: column;

        section.has-scroll-x {
            flex: 1 1 auto;
            min-height: 460px;

            &.skeleton {
                min-height: 425px;

                row.tarot-panel {
                    height: 200px;
                    min-width: 500px;
                }
            }
        }

        row.tarot-panel {
            padding: 0 10px;

            img {
                min-width: 104px;
            }

            a:last-child {
                padding-right: 10px;
            }
        }
    }

    panel-love panel-module-promo[placement="daily-insight"],
    panel-insight promoted-content promoted-content-story {
        margin: 0;
        min-height: 125px;

        row {
            padding: 0 10px;

            > column {
                p {
                    font-size: 18px;
                }

                img {
                    position: absolute;
                    top: 6px;
                }
            }
        }
    }
}
