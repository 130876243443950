.astrology-compatibility-header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 188px;

    @include is-mobile {
        height: 160px;
    }

    &.slant-friends {
        background-image: url("https://gfx.tarot.com/images/compats/friends.jpg");
    }

    &.slant-love {
        background-image: url("https://gfx.tarot.com/images/compats/love.jpg");
    }

    &.slant-work {
        background-image: url("https://gfx.tarot.com/images/compats/work.jpg");
    }

    &.slant-sex {
        background-image: url("https://gfx.tarot.com/images/compats/sex.jpg");
    }

    &.slant-chinese {
        background-image: url("https://gfx.tarot.com/images/compats/chinese.jpg");
    }

    &.slant-parenting {
        background-image: url("https://gfx.tarot.com/images/compats/parenting.jpg");
    }

    &.slant-sibling {
        background-image: url("https://gfx.tarot.com/images/compats/sibling.jpg");
    }
}

astrology-compatibility {
    display: block;
    margin-bottom: 500px;

    @include is-mobile {
        margin: 0 15px 500px 15px;
    }

    h1 {
        color: color(color-black);
        font-size: 42px;
        font-weight: 400;
        line-height: 1.4;
        margin: 0.2rem 0 0.5rem;
        text-align: center;

        @include is-mobile {
            font-size: 32px;
            line-height: 40px;
        }
    }

    p {
        color: color(color-black);
        font-size: 17px;
        font-weight: 400;
        line-height: 1.65em;
        margin: 0 auto 1.5rem;
        max-width: 650px;
        text-align: center;

        .short-description {
            padding-right: 10px;
        }

        &.learn-more {
            text-align: left;
        }
    }
}