main.full-width {
    margin: 0;
    width: 100%;

    &.show-nav {
        position: static;
    }

    row.has-background {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;

        &.background-magical {
            background-image: url("https://gfx.tarot.com/images/site/su-onboard/desktop/magickal.gif");
        }

        &.background-peace {
            background-image: url("https://gfx.tarot.com/images/site/su-onboard/desktop/peace.jpg");
        }

        &.background-butterfly {
            background-image: url("https://gfx.tarot.com/images/site/su-onboard/desktop/butterfly.gif");
        }

        &.background-unknown {
            background-image: url("https://gfx.tarot.com/images/site/su-onboard/desktop/unknown.gif");
        }
    }

    img.cosmic-profile-icon {
        height: 100px;
    }

    h4.cosmic-profile {
        text-align: center;
    }

    @include is-mobile {
        row.has-background {
            background-image: none !important; //sass-lint:disable-line no-important
            height: auto;

            &.background-butterfly {
                background-image: url("https://gfx.tarot.com/images/site/su-onboard/mobile/m-butterfly.gif");
                background-position: top center;
                background-size: cover;
            }
        }

        row.has-background-butterfly {
            background-image: url("https://gfx.tarot.com/images/site/su-onboard/mobile/m-butterfly.gif");
            background-position: top center;
        }

        row.mobile-cosmic-profile {
            background-color: color(wisp-pink);
        }

        h4.cosmic-profile {
            color: color(eminence);
            font-size: 11px;
            line-height: 1.27em;
        }

        img.cosmic-profile-icon {
            display: block;
            height: 40px;
            margin: 10px auto;
        }
    }
}
