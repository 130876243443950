// sass-lint:disable no-color-hex hex-notation hex-length no-color-keywords
$colors: (
    color-black: #000000,
    color-blue: #4194d1,
    color-crimson: #dc143c,
    color-cyan: #00ffff,
    color-gray: #808080,
    color-lavender: #ae6ed8,
    color-red: #ff0000,
    color-seashell: #f1f1f1,
    color-silver: #c0c0c0,
    color-white: #ffffff,
    affair: #714693,
    alabaster: #fafafa,
    alizarin-crimson: #da1d43,
    alto: #dbdbdb,
    aquarius: #4bb4eb,
    aqua-island: #a1dad7,
    aries: #fd5b78,
    bahama-blue: #026395,
    bermuda: #0095b6,
    bleach-white: #fef3d8,
    bon-jour: #e5e0e1,
    boulder: #7a7a7a,
    brilliant-rose: #f653a6,
    cancer: #a983f1,
    capricorn: #48d692,
    carrot-orange: #ed9121,
    cerise: #da3287,
    cerulean: #02a4d3,
    charm: #d47494,
    chatelle: #bdb3c7,
    cinderella: #fde1dc,
    cinnabar: #e45025,
    citrine-white: #fcf7e6,
    cloudy: #aca59f,
    concrete: #f2f2f2,
    cranberry: #db5079,
    danube: #6093d1,
    dark-burgundy: #770f05,
    daisy-bush: #4f2398,
    deep-cerulean: #007ba7,
    derby: #ffeed8,
    disco: #871550,
    dodger-blue: #1e90ff,
    dove-gray: #6d6c6c,
    dusty-gray: #a8989b,
    eminence: #6c3082,
    flamingo: #ef453e,
    forest-green: #228b22,
    fountain-blue: #56b4be,
    froly: #f57584,
    fruit-salad: #4f9d5d,
    fuchsia-pink: #c154c1,
    gallery: #efefef,
    gemini: #4bb4eb,
    geraldine: #fb8989,
    golden-tainoi: #ffcc5c,
    half-dutch-white: #fef7de,
    havelock-blue: #5590d9,
    iron: #d4d7d9,
    kaitoke-green: #004620,
    jagged-ice: #c2e8e5,
    jungle-green: #29ab87,
    lavender-blush: #fff0f5,
    lavender-purple: #967bb6,
    leo: #fd5b78,
    libra: #4bb4eb,
    light-orchid: #e1acca,
    lily: #c8aabf,
    link-water: #d9e4f5,
    lipstick: #ab0563,
    malibu: #7dc8f7,
    maroon-flush: #c32148,
    mauvelous: #f091a9,
    melanie: #dfb8df,
    melrose: #c7c1ff,
    mercury: #e5e5e5,
    mine-shaft: #323232,
    oslo-grey: #878d91,
    peach-cream: #fff0db,
    peppermint: #e3f5e1,
    pisces: #a983f1,
    polar: #e5f9f6,
    psychic: #c7dde5,
    red-violet: #c71585,
    remy: #feebf3,
    sagittarius: #fd5b78,
    sandy-brown: #f4a460,
    science-blue: #005ed1,
    scorpio: #a983f1,
    seance: #731e8f,
    shakespeare: #4eabd1,
    shamrock: #33cc99,
    silver-sand: #bfc1c2,
    snuff: #e2d8ed,
    sprout: #c1d7b0,
    sushi: #87ab39,
    tacao: #edb381,
    tahuna-sands: #eef0c8,
    tangerine: #f28500,
    taurus: #48d692,
    titan-white: #f0eeff,
    trinidad: #e64e03,
    trout: #4a4e5a,
    tundora: #4a4244,
    vanilla-ice: #f3d9df,
    virgo: #48d692,
    viridian: #40826d,
    vivid-violet: #803790,
    wedgewood: #4e7f9e,
    whisper: #f7f5fa,
    wisp-pink: #fef4f8,
    zanah: #daecd6,
    ziggurat: #bfdbe2,
    zumthor: #edf6ff
);

$zodiac-signs: (
    aries: #F64A8A,
    taurus: #3AB09E,
    gemini: #6093D1,
    cancer: #624E9A,
    leo: #F34723,
    virgo: #87AB39,
    libra: #456CAC,
    scorpio: #3F307F,
    sagittarius: #E32636,
    capricorn: #1D6142,
    aquarius: #1E385B,
    pisces: #803790
);

@function color($name, $lighten: 0, $opacity: 1) {
    @if map-has-key($colors, $name) {
        $value: map-get($colors, $name);

        @if $lighten >= 0 {
            $value: lighten($value, abs($lighten));
        }

        @if $lighten < 0 {
            $value: darken($value, abs($lighten));
        }

        @if $opacity < 1 {
            $value: rgba($value, $opacity);
        }

        @return $value;
    }

    @error "Invalid color name: `#{$name}`.";
}
