date-select {
    @include is-not-mobile {
        display: inline-block;
        width: 50%;
    }

    fieldset {
        &.chosen {
            select {
                background-color: color(eminence);
                color: color(color-white);

                &:hover {
                    background-color: color(eminence);
                }
            }

            label[for="month"]::after,
            label[for="day"]::after,
            label[for="year"]::after {
                border-top-color: color(color-white);
            }
        }
    }

    legend {
        color: color(boulder);
        font-family: $font-montserrat;
        font-size: 10px;
        font-weight: 400;
        padding: 8px;
    }

    select {
        appearance: none;
        background-color: color(mercury);
        background-image: none;
        border: 1px solid color(color-white);
        border-radius: 0;
        color: color(silver-sand);
        font-family: $font-montserrat;
        font-size: 16px;
        font-weight: 300;
        height: 39px;
        margin: 0;
        padding: 0 28px 0 8px;
        text-transform: capitalize;
        width: 100%;

        &:hover {
            background-color: color(mercury);
            border-color: color(color-white);
        }

        &:focus {
            outline: none;
        }

        option {
            appearance: none;
            background-color: color(color-white);
            color: color(color-black);
            font-size: 12px;
            font-weight: 300;
        }
    }

    label {
        &[for="month"],
        &[for="day"],
        &[for="year"] {
            display: inline-block;
            margin-right: 2%;
            position: relative;
            z-index: -1;

            &::after {
                @include disclosure-triangle;
                border-bottom: none;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 7px solid color(silver-sand);
                content: "";
                float: none;
                position: absolute;
                right: 8px;
                top: 15px;
            }
        }

        &[for="month"] {
            width: 45%;
        }

        &[for="day"] {
            width: 20%;
        }

        &[for="year"] {
            margin-right: 0;
            width: 25%;
        }

        &.date-input {
            color: color(boulder);
            font-family: $font-montserrat;
            font-size: 10px;
            font-weight: 400;

            input[type="date"],
            input[type="month"] {
                appearance: none;
                background-color: color(mercury);
                border: 1px solid color(color-white);
                color: color(silver-sand);
                font-family: $font-montserrat;
                font-size: 25px;
                font-weight: 300;
                height: 39px;
                margin: 8px 0 0 0;
                padding: 0 8px;

                &:focus {
                    background-color: color(mercury);
                    border: 1px solid color(color-white);
                    color: color(silver-sand);
                }
            }

            &.chosen {
                input[type="date"],
                input[type="month"] {
                    background-color: color(eminence);
                    color: color(color-white);

                    &:focus {
                        background-color: color(eminence);
                    }
                }
            }
        }
    }
}