toggle-group {
    align-content: stretch;
    align-items: stretch;
    background-color: color(color-white);
    border: solid 1px color(alto);
    border-radius: 5px;
    box-shadow: inset 0 1px 2px color(color-black, 0, 0.1);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 45px;
    justify-content: center;
    margin: 24px 0 21px 0;
    overflow: hidden;

    &:focus {
        background-color: color(alabaster);
        border-color: color(dusty-gray);
        box-shadow: 0 0 5px color(dusty-gray);
        outline: none;
    }

    &[disabled] {
        background-color: color(gallery);
        cursor: not-allowed;

        label {
            cursor: not-allowed;
        }
    }

    label {
        border-left: solid 1px color(alto);
        color: color(alto);
        flex-grow: 1;
        font-size: 11px;
        padding-top: 12px;
        text-align: center;
        user-select: none;

        &.checked {
            background-color: color(alto);
            color: color(color-black);
            font-weight: 400;
        }

        &:first-child {
            border: none;
        }
    }

    input[type="radio"] {
        display: none;
    }
}

label + toggle-group {
    margin-top: 5px;
}