birthday-select {
    display: inline;
    flex-grow: 1;
    margin: 7px 0 7px 3px;
    min-width: 280px;
    text-align: center;
    white-space: nowrap;

    @include is-desktop-and-tablet-landscape {
        display: block;
        min-width: 159px;
    }

    label {
        position: relative;

        &::after {
            @include disclosure-triangle;
            border-top-color: color(color-white);
            float: none;
            position: absolute;
            right: 12px;
            top: 5px;
        }
    }

    select {
        appearance: none;
        background-color: color(eminence);
        background-image: none;
        border: 1px solid color(color-white);
        border-radius: 10px;
        color: color(color-white);
        font-family: $font-montserrat;
        font-size: 12px;
        font-weight: 300;
        height: 39px;
        margin: 0 2px 0 0;
        padding: 0 8px;
        text-transform: capitalize;

        &[name="month"] {
            width: 85px;
        }

        &[name="day"] {
            width: 60px;
        }

        &[name="sign"] {
            width: 110px;
        }

        &:focus {
            outline: none;
        }
    }
}

.birthday-nav {
    li:nth-of-type(-n+3) {
        display: flex;
    }

    a.birthday-heading {
        line-height: 15px;
        text-align: left;
        white-space: normal;
        width: 65px;

        @include is-desktop-and-tablet-landscape {
            width: 130px;
        }

        @include is-tablet-portrait {
            width: auto;
        }

        @include is-mobile {
            font-size: 11px;
            line-height: 13px;
        }

        &:hover {
            text-decoration: underline;
        }

        &::after {
            display: none;
        }
    }

    @include is-mobile-and-tablet-portrait {
        display: flex !important; //sass-lint:disable-line no-important

        li:nth-of-type(n+3) {
            display: none;
        }
    }
}