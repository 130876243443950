date-picker {
    min-height: 24px;
    min-width: 24px;

    date-picker-icon {
        background-image: url("https://gfx.tarot.com/images/daily-pages/glyphs/calendar.svg");
        background-size: 24px 23px;
        cursor: pointer;
        display: inline-block;
        font-style: normal;
        height: 23px;
        position: relative;
        width: 24px;
    }

    date-picker-underlay {
        background-color: color(color-black, 0, 0.7);
        bottom: 0;
        height: 100%;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 26;

        @include is-mobile {
            background-color: color(eminence, 0, 0.7);
        }
    }

    date-picker-body {
        background-color: color(color-white);
        border-radius: 10px;
        left: 50%;
        max-height: 90vh;
        max-width: 448px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        user-select: none;
        width: 90vw;
        z-index: 27;

        date-picker-header {
            display: block;
            padding: 12px 0 0;
            position: relative;

            date-picker-close {
                color: color(color-white);
                cursor: pointer;
                display: block;
                font-size: 48px;
                font-weight: 400;
                position: absolute;
                right: -5px;
                top: -30px;
            }
        }

        date-picker-nav {
            display: block;

            a {
                color: color(color-black);
                cursor: pointer;

                @include is-tiny {
                    font-size: 24px;
                }
            }

            icon {
                border-bottom: 7px solid transparent;
                border-top: 7px solid transparent;
                cursor: pointer;
                display: inline-block;
                height: 0;
                width: 0;

                &.left {
                    border-right: 12px solid color(danube);
                }

                &.right {
                    border-left: 12px solid color(danube);
                }
            }
        }

        date-picker-scroll {
            display: block;
            max-height: 390px;
            overflow-y: auto;
        }

        date-picker-calendar {
            display: block;
            padding: 10px 20px 0;

            date-picker-day-names {
                background-color: color(link-water);
                display: grid;
                grid-template-columns: calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7);
                text-align: center;
                width: 100%;

                span {
                    color: color(color-black);
                    display: inline-grid;
                    font-size: 14px;
                    line-height: 28px;
                }
            }

            date-picker-days {
                display: grid;
                grid-template-columns: calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7);
                text-align: center;
                width: 100%;

                span {
                    border-radius: 50%;
                    color: color(color-black, 0, 0.87);
                    cursor: pointer;
                    display: inline-grid;
                    font-size: 24px;
                    line-height: 50px;
                    position: relative;
                    transition: color 0.22s;
                    user-select: none;

                    @include is-tiny {
                        font-size: 20px;
                        line-height: 40px;
                    }

                    &::before {
                        background-color: color(danube);
                        border-radius: 50%;
                        content: "";
                        height: 50px;
                        left: calc(50% - 25px);
                        opacity: 0;
                        position: absolute;
                        transform: scale(0);
                        transition: transform 0.22s, opacity 0.22s;
                        width: 50px;
                        z-index: -1;

                        @include is-tiny {
                            height: 36px;
                            left: 0;
                            width: 36px;
                        }
                    }

                    &[disabled="true"] {
                        color: color(color-black, 0, 0.2);
                        cursor: unset;
                    }

                    &.today {
                        color: color(danube);
                        font-size: 32px;
                        font-weight: 600;

                        @include is-tiny {
                            font-size: 24px;
                        }
                    }

                    &.selected {
                        color: color(color-white);
                        font-size: 32px;
                        font-weight: 600;

                        @include is-tiny {
                            font-size: 24px;
                        }

                        &::before {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
            }
        }

        date-picker-buttons {
            display: block;
            padding: 8px;
            text-align: right;

            button {
                appearance: none;
                background-color: transparent;
                border: none;
                border-radius: 3px;
                cursor: pointer;
                font-size: 15px;
                line-height: 36px;
                margin-left: 8px;
                min-width: 64px;
                padding: 0 16px;
                transition: background-color 0.13s;

                &:active,
                &:focus,
                &:hover {
                    background-color: color(color-black, 0, 0.055);
                    outline: none;
                }
            }
        }
    }
}
