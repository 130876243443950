product-carousel {
    align-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    overflow-x: hidden;
    position: relative;

    @include is-mobile {
        overflow-x: scroll;
    }

    > a {
        aspect-ratio: 2 / 3; //sass-lint:disable-line no-misspelled-properties
        border: 1px solid color(silver-sand);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin-right: 10px;
        padding: 10px;
        transition: margin-left 0.5s ease-in-out;
        width: calc(25% - 10px);

        @include is-mobile {
            width: 155px;
        }

        &:hover {
            text-decoration: none;
        }

        &.move {
            margin-left: calc(25% * -1);
        }

        > img {
            display: block;
            margin-bottom: 10px;
            width: 100%;
        }

        > p {
            color: color(mine-shaft);
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            margin: auto 7px;
            text-align: center;
        }
    }

    .product-carousel-nav {
        background-color: color(color-white);
        cursor: pointer;
        height: 100%;
        padding: 0 5px;
        position: absolute;
        right: 0;
        width: 40px;

        &:hover {
            background-color: color(color-white, 0, 0.5);
        }

        @include is-mobile {
            display: none;
        }
    }

}

promo-message {
    &[mode="vip-sidebar"] {
        product-carousel {
            align-items: flex-start;
            flex-direction: column;
            margin: 0;

            > a {
                margin: 10px 0 0 0;
                width: 100%;

                @include is-not-mobile {
                    &:nth-of-type(n+3) {
                        display: none;
                    }
                }
            }

            .product-carousel-nav {
                display: none;
            }
        }
    }
}
