.karmacoin-store {
    margin-bottom: 75px;
    padding: 0 125px;
    text-align: center;

    @include is-mobile {
        padding: 0 5px;
    }

    .icon-karma-coin-store {
        background-image: url("https://gfx.tarot.com/images/site/tarot/karma-coin-store/icon.svg");
        background-position: 50%;
        background-repeat: no-repeat;
        height: 65px;
        margin: 0 auto 10px;
        max-width: 25%;

        @include is-not-mobile {
            background-position: 0 0;
            height: 75px;
            margin-top: 5px;
            width: 85px;
        }
    }


    h1 {
        font-weight: 300;

        sup {
            font-size: 50%;
            top: -0.7em;
        }
    }

    p {
        padding: 5px 0;

        &.date {
            color: color(eminence);
            font-weight: 400;
            padding: 14px 0;
            text-transform: uppercase;
        }
    }
}
