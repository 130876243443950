kc-store {
    display: block;
    height: 540px;
    margin: 0 0 50px 10px;
    max-width: 750px;
    position: relative;
    width: calc(100vw - 10px);

    &.sale {
        height: 570px;
    }

    @include is-desktop-and-tablet-landscape {
        margin: 0 auto 50px auto;
    }

    kc-key {
        background: color(color-white, 1, 0.97);
        padding: 169px 10px 10px 0;
        position: absolute;
        z-index: 2;

        @include is-desktop-and-tablet-landscape {
            left: -64px;
        }

        p {
            font-size: 11px;
            margin: 0;
            text-align: right;

            &:nth-of-type(1) {
                margin-bottom: 13px;
            }

            &:nth-of-type(2) {
                margin-bottom: 58px;

                &.sale {
                    margin-bottom: 87px;
                }
            }

            &:last-of-type {
                line-height: 13px;
            }
        }
    }

    kc-packages {
        display: block;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 0 0 64px;
        scrollbar-width: none; //sass-lint:disable-line no-misspelled-properties (Firefox-specific)

        &::-webkit-scrollbar { //sass-lint:disable-line no-vendor-prefixes
            display: none;
        }

        @include is-desktop-and-tablet-landscape {
            overflow-x: hidden;
            padding: 0;
        }

        column {
            flex: 1;

            &.biggest::before,
            &.popular::before {
                color: color(carrot-orange);
                display: block;
                margin: 0 auto 10px auto;
                text-align: center;
                width: 70px;
            }

            &.biggest::before {
                content: "BIGGEST SAVINGS";
            }

            &.popular::before {
                content: "MOST POPULAR";
            }

            &:not(.biggest, .popular) {
                padding-top: 48px;
            }

            kc-package {
                border: 1px solid color(carrot-orange);
                border-radius: 15px;
                cursor: pointer;
                display: block;
                margin: 0 5px 36px;
                overflow: hidden;
                padding: 0 0 20px;

                &.active {
                    margin-bottom: 28px;
                    padding-bottom: 28px;

                    kc-package-header {
                        padding: 23px 20px 15px 20px;
                    }
                }

                > p {
                    margin-bottom: 15px;
                }

                kc-package-header {
                    background-color: color(half-dutch-white);
                    display: block;
                    margin: 0 0 20px 0;
                    padding: 20px 20px 10px 20px;

                    p {
                        margin: 0;
                    }
                }
            }
        }
    }

    .kc-total {
        h2 {
            display: inline-block;

            &.sale {
                color: color(sushi);
                font-weight: 700;
                margin-right: 15px;

                @include is-not-mobile {
                    margin-right: 35px;
                }
            }
        }

        h3 {
            display: inline-block;
            margin-right: 15px;

            @include is-not-mobile {
                margin-right: 35px;
            }

            &.sale {
                margin-right: 0;
                text-decoration: line-through;
            }
        }
    }
}
