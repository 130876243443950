promo-message {
    display: block;

    .button-wrapper {
        display: block;
        padding: 5px 0;
        width: 100%;

        a {
            color: color(color-white);
        }
    }

    &[placement="banner"] {
        > a {
            color: inherit;
            cursor: pointer;
            display: block;
            margin-bottom: 15px;
            padding: 10px;
            text-decoration: inherit;

            @include is-desktop {
                min-height: 360px;
                overflow: visible;
                padding: 10px 0;

                row {
                    margin: 0 auto;
                    width: 1000px;
                }
            }

            @include is-tablet {
                overflow: visible;
                padding: 0 0 0 10px;

                row {
                    margin: 0 auto;
                    width: 800px;
                }
            }

            @include is-tablet-landscape {
                row {
                    margin: 0 auto;
                    width: 900px;
                }
            }
        }

        .banner-promo-image {
            border-radius: 13px;
            box-shadow: 0 0 5px 2px color(color-black, 0, 0.2);
            max-height: 300px;
            @include is-desktop {
                transform: rotate(-5deg);
            }
        }

        .button-20-pct {
            margin-left: 1em;
            padding-left: 5em;
            position: relative;

            &::after {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' viewBox='0, 0, 100, 100' id='solid_star_polygon_18.svg'%3E%3Cpath d='M 95.454,49.240 42.106,4.476 7.286,64.786 72.727,88.605 84.820,20.023 15.180,20.023 27.273,88.605 92.713,64.787 57.893,4.476 4.546,49.240 57.893,94.004 92.713,33.69 27.273,9.876 15.180,78.460 84.820,78.460 72.727,9.876 7.287,33.69 42.107,94.004 95.455,49.240z' fill='black'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                content: "20% \A Off ";
                display: block;
                font-size: 16px;
                font-weight: 400;
                height: 5.5em;
                left: -1em;
                padding-left: 0.4em;
                padding-top: 1.4em;
                position: absolute;
                top: -1.5em;
                transform: rotate(-9deg);
                white-space: pre-wrap;
                width: 5.5em;
            }
        }

        h2 {
            font-size: 44px;
            line-height: 1em;
            margin: 0 0 10px 0;
        }

        h3 {
            font-size: 20px;
            font-weight: 400;
            line-height: 1.25em;
            margin: 10px 0;
            padding-right: 5px;
        }

        p {
            font-size: 16px;
            margin: 0 0 20px;
            padding: 10px;
            text-align: left;
        }

        &.justify-center {
            row {
                &:nth-of-type(2) {
                    justify-content: center;
                }
            }
        }
    }

    &[placement="tcod-upsell"],
    &[placement="hexagram-upsell"] {
        margin: 0 20px 20px 20px;
    }

    &[placement^="bday"] {
        img {
            width: 100%;
        }

        h1 {
            @include is-mobile {
                margin: 5px 10px 0 10px;
            }
        }

        p {
            margin: 0 0 15px 0;

            @include is-mobile {
                margin: 0 10px 15px;
            }
        }

        a.button-sidebar {
            @include is-mobile {
                margin-left: 10px;
            }
        }
    }

    &[placement^="sidebar"] {
        margin: 0 0 25px 0;
        min-height: 575px;

        img {
            display: block;
            width: 100%;
        }

        h3 {
            margin: 10px 0;
        }

        p {
            margin: 0 0 15px;
        }
    }

    &[placement^="yearly-forecast"] {
        text-align: center;
    }

    &[placement^="text-vip-message"] {
        > p {
            background: color(bon-jour);
            color: color(eminence);
            font-size: 14px;
            line-height: 23px;
            margin: 0 0 25px;
            padding: 10px;
            text-align: center;
        }
    }

    &[placement^="text-checkout"] {
        > p {
            background: color(sushi);
            color: color(color-white);
            font-size: 14px;
            margin: 0 0 10px;
            padding: 10px;
            text-align: center;
        }
    }

    &[placement="text-catalog"]  {
        > p {
            background-color: color(wisp-pink);
            color: color(eminence);
            font-size: 16px;
            margin: 20px 0 0;
            padding: 14px;
            text-align: center;

            @include is-mobile {
                font-size: 14px;
                line-height: 24px;
                text-wrap: pretty;
            }
        }
    }

    &[placement="product-carousel"] {
        min-height: 250px;
        width: 100%;
    }

    &[placement="tarot-page"] {
        min-height: 49px;

        @include is-mobile {
            min-height: 28px;
        }

        &[tags="deck-lp"],
        &[tags="card-lp-0"],
        &[tags="card-ind-0"],
        &[tags="deck-ind-0"] {
            min-height: 0;

            a {
                align-items: center;
                background-color: color(cerulean);
                border-radius: 10px;
                color: color(color-white);
                display: flex;
                height: 57px;
                padding: 0 15px;
                text-align: center;

                &:hover {
                    text-decoration: none;
                }

                @include is-not-mobile {
                    display: inline-flex;
                }
            }
        }

        &[tags="card-lp-x"],
        &[tags="deck-lp-x"],
        &[tags="card-ind-x"],
        &[tags="deck-ind-x"] {
            height: 620px;
            overflow: hidden;

            @include is-tiny {
                height: 680px;
            }

            @include is-tablet-portrait {
                height: 420px;
            }

            @include is-tablet-landscape {
                height: 330px;
            }

            @include is-desktop {
                height: 330px;
            }
        }
    }

    &[placement="free-reading-upsell"] {
        min-height: 265px;

        @include is-mobile {
            min-height: 185px;
        }
    }

    &[placement="featured-spread"] {
        min-height: 303px;

        @include is-not-mobile {
            min-height: 363px;
        }
    }

    &[placement="daily-insight"] {
        min-height: 148px;

        @include is-not-mobile {
            min-height: 120px;
        }

        p {
            @include is-mobile {
                font-size: 24px;
                line-height: 26px;
            }
        }
    }

    &[placement="text-top"] a {
        display: inherit;
        font-size: 11px;
        line-height: 26px;
        text-align: center;
        text-decoration: none;
    }
}
